@charset "UTF-8";

.l-section {
  margin-top: 80px;
  @include mq-max("sm") {
    margin-top: 30px;
  };
  &._no-padding {
    margin-top: 0;
    @include mq-max("sm") {
      margin-top: 0;
    };
  }
  .m-sub-heading:first-child {
    margin-top: 0;
  }
  .l-flex:last-child,
  .m-figure-box:last-child {
    margin-bottom: 0;
  }
}
