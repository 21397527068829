@charset "UTF-8";

.p-form {
  max-width: 710px;
  width: 100%;
  margin: 30px auto 0;
  &_row {
    display: flex;
    margin-bottom: 40px;
    @include mq-max("sm") {
      flex-direction: column;
      margin-bottom: 20px;
      padding: 0 vw(30);
    };
  }
  &_label {
    flex: 1 0 auto;
    padding-top: 1em;
    font-weight: bold;
    font-size: rem(15);
    letter-spacing: .05em;
  }
  &_inputs {
    flex: 0 0 auto;
    width: 500px;
    @include mq-max("sm") {
      width: 100%;
    };
  }
  &_input , &_textarea {
    width: 100%;
    padding: 1em;
    border: 0;
    background-color: c("white");
  }
  &_required {
    font-size: rem(10);
    color: #b6272d;
  }
  &_radios {
    flex: 0 0 auto;
    width: 500px;
    padding: 1em 0;
    line-height: 1;
    @include mq-max("sm") {
      width: 100%;
    };
    .mwform-radio-field-text {
      position: relative;
      display: inline-block;
      padding-left: 24px;
      &::before {
        position: absolute;
        display: block;
        content: "";
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border: 2px solid #000;
        border-radius: 50%;
        background-color: c("white");
      }
      &::after {
        position: absolute;
        display: none;
        content: "";
        left: 5px;
        top: 5px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #000;
      }
    }
    input[type="radio"]:checked + .mwform-radio-field-text {
      &::after {
        display: block;
      }
    }
  }
  &_radio {
    display: none;
  }
  &_footer {
    display: flex;
  }
  &_btn {
    flex: 1;
    padding: 1em;
    margin: 10px;
    color: c("white");
    border: 0;
    background-color: c("gray");
    font-size: rem(19);
    font-weight: bold;
    line-height: 1;
    &:visited {
      color: c("white");
    }
    &._submit {
      background-color: #000;
    }
  }
  &_complete {
    margin-top: 30px;
    font-size: rem(24);
    letter-spacing: .34em;
    @include mq-max("sm") {
      margin: 30px vw(30) 0;
      font-size: rem(24);
      letter-spacing: .08em;
    };
  }
  &_text {
    margin: 30px 0;
    text-align: center;
    @include mq-max("sm") {
      margin: 30px vw(30);
    };
  }
}
.mw_wp_form_confirm {
  .p-form_label , .p-form_radios {
    padding-top: 0;
  }
  .contact-description {
    p {
      display: none;
    }
  }
}
.mw_wp_form_complete {
  text-align: center;
  .contact-description {
    p {
      display: none;
    }
  }
}
