@charset "UTF-8";

.m-heading {
  background-color: c("hilight-gray");
  text-align: center;
  font-size: rem(23);
  letter-spacing: .34em;
  padding: .175em 0;
  @include mq-max("sm") {
    font-size: rem(18);
  };
}
