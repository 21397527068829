@charset "UTF-8";

.l-block {
  margin-top: 60px;
  @include mq-max("sm") {
    margin-top: 30px;
  };
  &._border {
    padding-top: 30px;
    border-top: 1px solid c("border");
  }
  .l-flex:last-child,
  .m-figure-box:last-child {
    margin-bottom: 0;
  }
}
