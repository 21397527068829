@charset "UTF-8";

.staff {
  .c-visual {
    background: url("../images/staff/visual.jpg") center no-repeat;
    background-size: cover;
  }
  &-section {
    display: flex;
    @include mq-max("sm") {
      flex-direction: column;
    };
    & + .staff-section {
      margin-top: 80px;
      @include mq-max("sm") {
        margin-top: 40px;
      };
    }
  }
  &-img {
    flex: 0 0 per(430, 1000);
  }
  &-content {
    flex: 1;
    margin-left: 35px;
    @include mq-max("sm") {
      margin: 20px 0 0;
    };
    h2 {
      display: flex;
      align-items: flex-end;
      margin: 0 0 15px;
      padding-bottom: 15px;
      font-size: rem(27);
      border-bottom: 1px solid #040000;
      letter-spacing: .34em;
      line-height: 1;
      @include mq-max("sm") {
        font-size: rem(24);
        letter-spacing: .1em;
      };
    }
    h3 {
      margin: 0 0 20px;
      font-weight: bold;
      color: #816239;
      letter-spacing: .34em;
      line-height: 1;
      @include mq-max("sm") {
        letter-spacing: .1em;
      };
    }
  }
  &-en {
    margin-left: auto;
    font-size: rem(19);
    color: #bdbebe;
  }
  &-comment {
    font-size: rem(15);
    h4 {
      margin: 0;
    }
    p {
      &:first-of-type {
        margin-top: 0;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
