@charset "UTF-8";

.about {
  .c-visual {
    background: url("../images/about/visual.jpg") center no-repeat;
    background-size: cover;
  }
  .m-heading {
    margin-bottom: 0;
  }
  h2 {
    padding-left: 1em;
    font-size: rem(21);
    letter-spacing: .34em;
    font-weight: normal;
  }
  &-container {
    margin-bottom: 80px;
    padding: 30px 55px;
    border-top: 1px solid #a8a8a8;
    border-bottom: 1px solid #a8a8a8;
    @include mq-max("sm") {
      padding: 18px;
      br {
        display: none;
      }
    };
  }
  &-dl {
    border-top: 1px solid #a8a8a8;
    margin-bottom: 80px;
    @include mq-max("sm") {
      margin-bottom: 50px;
    };
    div {
      display: flex;
      padding: 24px;
      border-bottom: 1px solid #a8a8a8;
      letter-spacing: .34em;
      @include mq-max("sm") {
        padding: 18px;
        flex-direction: column;
      };
      dt {
        flex: 0 0 18%;
        margin: 0;
        padding: 0;
      }
      dd {
        flex: 1;
        margin: 0;
        padding: 0;
      }
    }
  }
  &-map {
    margin-bottom: 60px;
    @include mq-max("sm") {
      margin-bottom: 30px;
    };
    iframe {
      width: 100%;
      height: 530px;
      @include mq-max("sm") {
        height: 360px;
      };
    }
  }
  &-access {
    text-align: center;
    h2 {
      margin: 0;
    }
    p {
      letter-spacing: .34em;
    }
  }
}
