@charset "UTF-8";

.p-contact {
  display: flex;
  align-items: center;
  max-width: 1000px;
  width: 100%;
  margin: 40px auto;
  padding: 40px 30px;
  background-color: c("white");
  text-align: center;
  border: 1px solid c("light-gray");
  @include mq-max("sm") {
    flex-direction: column;
    width: auto;
    margin: 30px vw(30);
    padding: 20px;
  };
  &_content {
    flex: 1 0 auto;
    text-align: center;
  }
  &_img {
    width: 300px;
    @include mq-max("sm") {
      width: 100%;
    };
  }
  p {
    font-size: rem(18);
    font-weight: bold;
    @include mq-max("sm") {
      margin-top: 0;
    };
    a {
      display: inline-block;
      font-size: rem(32);
      color: c("white");
      background-color: c("black");
      border-radius: 1.5em;
      padding: 0 1em;
      transition: opacity .15s;
      @include mq-max("sm") {
        font-size: rem(24);
      };
      &:hover {
        text-decoration: none;
        opacity: .75;
      }
    }
  }
}
