@charset "UTF-8";

.l-flex {
  display: flex;
  margin: 2em 0;
  @include mq-max("md") {
    flex-direction: column;
    margin: 1em 0;
  };
}
