@charset "UTF-8";

.single {
  .c-visual {
    background: url("../images/news/visual.jpg") center no-repeat;
    background-size: cover;
  }
  &_container {
    display: flex;
    @include mq-max("sm") {
      flex-direction: column;
    };
  }
  &_main {
    flex: 1 1 auto;
    max-width: 680px;
    @include mq-max("sm") {
      max-width: none;
    };
  }
  &_side {
    flex: 1 0 auto;
    margin-left: 40px;
    letter-spacing: .34em;
    @include mq-max("sm") {
      margin: 40px 0 0;
    };
    h3 {
      margin: 0 0 .5em;
      font-size: rem(15);
    }
  }
  &_list {
    margin: 0;
    padding: 0;
    list-style: none;
    border-top: 1px solid #323333;
    li {
      border-bottom: 1px solid #323333;
      a {
        display: block;
        padding: .5em 0;
        color: c("black");
      }
    }
  }
  &_thumb {
    flex: 0 0 per(270, 1000);
    text-align: center;
    @include mq-max("sm") {
      margin: 0 0 15px;
    };
  }
  &_content {
    word-break: break-all;
    div {
      max-width: 100%;
    }
    img {
      max-width: 100%;
    }
    blockquote {
      background-color: c("white");
      padding: 15px;
    }
    pre {
      max-width: 100%;
      word-break: break-all;
      white-space: pre-wrap;
    }
  }
  &_header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #323333;
    margin-bottom: 40px;
    padding-bottom: 15px;
    @include mq-max("sm") {
      flex-direction: column;
      align-items: flex-start;
    };
    .m-category {
      flex: 0 0 auto;
      display: block;
      margin-left: 10px;
      @include mq-max("sm") {
        margin-left: 0;
      };
    }
  }
  &_date {
    font-size: rem(13);
  }
  &_title {
    margin: 0;
    font-size: rem(16);
    font-weight: bold;
    margin-right: auto;
    a {
      color: c("text");
      word-break: break-all;
    }
  }
  &_footer {
    display: flex;
    font-size: rem(14);
    ._prev {
      margin-right: auto;
      a {
        color: c("text");
      }
    }
    ._next {
      margin-left: auto;
      a {
        color: c("text");
      }
    }
  }
}
