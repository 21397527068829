@charset "UTF-8";

:root {
  font-size: $_font-size;
}

body {
  font-family: $_font-family;
  line-height: $_line-height;
  color: $_text-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  letter-spacing: .08em;
  background-color: c("bg");
  padding-top: 80px;
  @include mq-max("md") {
    padding-top: 60px;
  };
}

figure {
  margin-left: 0;
  margin-right: 0;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  backface-visibility: hidden;
  -ms-backface-visibility: visible; // for IE11 transition bug
}

a {
  color: $_text-link-color;
  text-decoration: none;
  img {
    transition: opacity $_transition-duration;
  }
  &:hover {
    color: $_text-link-hover-color;
    text-decoration: underline;
    img {
      opacity: $_opacity;
    }
  }
  &:visited {
    color: $_text-link-visited-color;
  }
}

input, textarea, select, button {
  font-family: inherit;
}

.grecaptcha-badge {
  opacity: 0;
}
