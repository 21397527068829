@charset "UTF-8";

.l-container {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  @include mq-max("sm") {
    width: auto;
    margin: 0 vw(40);
  };
  &._narrow {
    max-width: 900px;
  }
}
