@charset "UTF-8";

////
//// @group 00. 設定
////

/// pxからvwへ変換する際の基準値
///
/// @since 1.0.0

$_viewport-width: 750px;

/// pxからvhへ変換する際の基準値
///
/// @since 1.0.0

$_viewport-height: 1136px;

/// グリッドのカラム数
///
/// @since 1.2.0

$_grid-columns: 24;

/// グリッドアイテム間のスペースの幅
///
/// @since 1.2.0

$_grid-gutter: 2%;

/// ブレークポイント
///
/// @since 1.0.0

$_breakpoints: (
  "xs": 0,
  "sm": 480px,
  "md": 768px,
  "lg": 1024px,
  "xl": 1440px
);

/// フォントファミリー
///
/// @since 1.0.0

$_font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;

/// フォントサイズ
/// - 16px (一般的なブラウザのデフォルトフォントサイズ)を推奨
///
/// @since 1.0.0

$_font-size: 16px;

/// 行間
///
/// @since 1.5.2

$_line-height: 1.75;

/// テキストカラー
///
/// @since 1.0.0

$_text-color: #333;

/// テキストリンクカラー
///
/// @since 1.0.0

$_text-link-color: #f40000;

/// マウスオーバー時のテキストリンクカラー
///
/// @since 1.0.0

$_text-link-hover-color: #c00;

/// 訪問済みのテキストリンクカラー
///
/// @since 1.0.0

$_text-link-visited-color: #c00;

/// マウスオーバー時などの不透明度
///
/// @since 1.0.0

$_opacity: .75;

/// アニメーションスピード
///
/// @since 1.0.0

$_transition-duration: .15s;

/// サイトでの使用色<br>
/// キーに識別名、値にカラーコードを指定する
///
/// @since 1.0.0

$_colors: (
  "text":              $_text-color,
  "text-link":         $_text-link-color,
  "text-link-hover":   $_text-link-hover-color,
  "text-link-visited": $_text-link-visited-color,
  "white":             #fff,
  "black":             #231815,
  "bg":                #f6f6f6,
  "thin-gray":         #f6f6f6,
  "hilight-gray":      #dbdbdb,
  "light-gray":        #a8a8a8,
  "gray":              #4d4d4d,
  "information":       #008745,
  "event":             #882784,
  "red":               #c00
);

/// z-index管理用変数<br>
/// キーに識別名、値にz-index値を指定する
///
/// @since 2.8.0

$_z-indexes: (
  "header": 500,
  "nav": 400
);
