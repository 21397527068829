@charset "UTF-8";

.m-banner {
  display: inline-block;
  margin: 0 auto;
  img {
    width: 100%;
  }
  &+.m-banner {
    margin-top: 15px;
  }
}
