@charset "UTF-8";

.m-category {
  padding: .25em 1em;
  border-radius: 4px;
  border: 1px solid c("black");
  font-size: rem(14);
  line-height: 1;
  &._information {
    color: c("information");
    border-color: c("information");
  }
  &._event {
    color: c("event");
    border-color: c("event");
  }
}
