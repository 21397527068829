@charset "UTF-8";

.archive {
  .c-visual {
    background: url("../images/news/visual.jpg") center no-repeat;
    background-size: cover;
  }
  &_item {
    display: flex;
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #323333;
    @include mq-max("sm") {
      flex-direction: column;
    };
  }
  &_thumb {
    flex: 0 0 per(270, 1000);
    margin-right: 35px;
    text-align: center;
    @include mq-max("sm") {
      margin: 0 0 15px;
    };
  }
  &_content {
    flex: 1 1 auto;
    word-break: break-all;
  }
  &_header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #323333;
    margin-bottom: 15px;
    padding-bottom: 15px;
    @include mq-max("sm") {
      flex-direction: column;
      align-items: flex-start;
    };
    .m-category {
      flex: 0 0 auto;
      display: block;
      margin-left: 10px;
      @include mq-max("sm") {
        margin-left: 0;
      };
    }
  }
  &_date {
    flex: 0 0 auto;
    font-size: rem(13);
    color: #a8a8a8;
    margin-right: 15px;
  }
  &_title {
    font-size: rem(16);
    font-weight: bold;
    margin-right: auto;
    a {
      color: c("text");
      word-break: break-all;
    }
  }
  &_more {
    margin-top: 10px;
    a {
      color: c("text");
      text-decoration: underline;
      &:visited {
        color: c("text");
      }
      &:hover {
        text-align: none;
      }
    }
  }
}
