@charset "UTF-8";

.real-estate {
  &-container {
    display: flex;
    flex-wrap: wrap;
    @include mq-max("sm") {
      flex-direction: column;
    };
    & + .real-estate-container {
      margin-top: 60px;
      @include mq-max("sm") {
        margin-top: 0;
      };
    }
  }
  &-box {
    max-width: 428px;
    width: per(428, 900);
    margin-right: auto;
    margin-top: 40px;
    @include mq-max("sm") {
      max-width: none;
      width: 100%;
      margin-top: 20px;
      margin-right: 0;
      margin-bottom: 30px;
    };
    &:nth-of-type(even) {
      margin-right: 0;
    }
    img {
      width: 100%;
    }
    h3 {
      margin: 15px 0;
      font-size: rem(16);
      letter-spacing: .34em;
      line-height: 1;
    }
    h4 {
      margin: 0 0 20px;
      font-size: rem(16);
      font-weight: normal;
      line-height: 1;
    }
  }
  &-area {
    margin: 1em 0;
    font-size: rem(15);
    letter-spacing: .34em;
  }
  &-footer {
    display: flex;
    font-size: rem(16);
    font-weight: bold;
    letter-spacing: .34em;
    text-align: center;
  }
  &-detail {
    display: block;
    flex: 1;
    margin-right: 24px;
    padding: .5em 0;
    background-color: #c1c1c1;
    color: #000;
    transition: all .15s;
    &:visited {
      color: #000;
    }
    &:hover {
      color: #000;
      opacity: .75;
      text-decoration: none;
    }
  }
  &-contact {
    display: block;
    flex: 1;
    padding: .5em 0;
    background-color: #9b7c52;
    color: #fff;
    transition: all .15s;
    &:visited {
      color: #fff;
    }
    &:hover {
      color: #fff;
      opacity: .75;
      text-decoration: none;
    }
  }
}
.post-type-archive-real-estate {
  .c-visual {
    background: url("../images/real-estate/visual.jpg") center no-repeat;
    background-size: cover;
  }
  .l-container + .l-container {
    margin-top: 70px !important;
    @include mq-max("sm") {
      margin-top: 40px !important;
    };
  }
}
