@charset "UTF-8";
/*! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */
/* Document (https://html.spec.whatwg.org/multipage/semantics.html#semantics)
   ========================================================================== */
/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add box sizing inheritence in all browsers (opinionated).
 */
*,
::before,
::after {
  background-repeat: no-repeat;
  /* 1 */
  box-sizing: inherit;
  /* 2 */
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritence in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */
}

/**
 * 1. Add border box sizing in all browsers (opinionated).
 * 2. Add the default cursor in all browsers (opinionated).
 * 3. Prevent font size adjustments after orientation changes in IE and iOS.
 */
html {
  box-sizing: border-box;
  /* 1 */
  cursor: default;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
}

/* Sections (https://html.spec.whatwg.org/multipage/semantics.html#sections)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: .67em 0;
}

/* Grouping content (https://html.spec.whatwg.org/multipage/semantics.html#grouping-content)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in IE.
 */
figcaption,
figure,
main {
  /* 1 */
  display: block;
}

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Text-level semantics (https://html.spec.whatwg.org/multipage/semantics.html#text-level-semantics)
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic;
}

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ffff00;
  color: #000000;
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

/*
 * Remove the text shadow on text selections (opinionated).
 * 1. Restore the coloring undone by defining the text shadow (opinionated).
 */
::-moz-selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none;
}

::selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none;
}

/* Embedded content (https://html.spec.whatwg.org/multipage/embedded-content.html#embedded-content)
   ========================================================================== */
/*
 * Change the alignment on media elements in all browers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg {
  fill: currentColor;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Tabular data (https://html.spec.whatwg.org/multipage/tables.html#tables)
   ========================================================================== */
/**
 * Collapse border spacing
 */
table {
  border-collapse: collapse;
}

/* Forms (https://html.spec.whatwg.org/multipage/forms.html#forms)
   ========================================================================== */
/**
 * Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  margin: 0;
}

/**
 * Inherit styling in all browsers (opinionated).
 */
button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
 * 1. Remove the default vertical scrollbar in IE.
 * 2. Change the resize direction on textareas in all browsers (opinionated).
 */
textarea {
  overflow: auto;
  /* 1 */
  resize: vertical;
  /* 2 */
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/* Interactive elements (https://html.spec.whatwg.org/multipage/forms.html#interactive-elements)
   ========================================================================== */
/*
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 */
details,
menu {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/* Scripting (https://html.spec.whatwg.org/multipage/scripting.html#scripting-3)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* User interaction (https://html.spec.whatwg.org/multipage/interaction.html#editing)
   ========================================================================== */
/*
 * Remove the tapping delay on clickable elements (opinionated).
 * 1. Remove the tapping delay in IE 10.
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation;
}

/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none;
}

/* ARIA (https://w3c.github.io/html-aria/)
   ========================================================================== */
/**
 * Change the cursor on busy elements (opinionated).
 */
[aria-busy="true"] {
  cursor: progress;
}

/*
 * Change the cursor on control elements (opinionated).
 */
[aria-controls] {
  cursor: pointer;
}

/*
 * Change the display on visually hidden accessible elements (opinionated).
 */
[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements (opinionated).
 */
[aria-disabled] {
  cursor: default;
}

:root {
  font-size: 16px;
}

body {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  line-height: 1.75;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  letter-spacing: .08em;
  background-color: #f6f6f6;
  padding-top: 80px;
}

@media (max-width: 1023px) {
  body {
    padding-top: 60px;
  }
}

figure {
  margin-left: 0;
  margin-right: 0;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  backface-visibility: hidden;
  -ms-backface-visibility: visible;
}

a {
  color: #f40000;
  text-decoration: none;
}

a img {
  transition: opacity 0.15s;
}

a:hover {
  color: #c00;
  text-decoration: underline;
}

a:hover img {
  opacity: 0.75;
}

a:visited {
  color: #c00;
}

input, textarea, select, button {
  font-family: inherit;
}

.grecaptcha-badge {
  opacity: 0;
}

.l-block {
  margin-top: 60px;
}

@media (max-width: 767px) {
  .l-block {
    margin-top: 30px;
  }
}

.l-block._border {
  padding-top: 30px;
  border-top: 1px solid;
}

.l-block .l-flex:last-child,
.l-block .m-figure-box:last-child {
  margin-bottom: 0;
}

.l-body {
  flex: 1;
}

@media (max-width: 1439px) {
  .l-body {
    margin: 0 10px;
  }
}

@media (max-width: 767px) {
  .l-body {
    margin: 0;
  }
}

@media (max-width: 767px) {
  .l-body p {
    margin: .5em 0;
    font-size: 0.8125rem;
    line-height: 2;
  }
}

.l-container {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .l-container {
    width: auto;
    margin: 0 5.33333vw;
  }
}

.l-container._narrow {
  max-width: 900px;
}

.l-flex {
  display: flex;
  margin: 2em 0;
}

@media (max-width: 1023px) {
  .l-flex {
    flex-direction: column;
    margin: 1em 0;
  }
}

.l-section {
  margin-top: 80px;
}

@media (max-width: 767px) {
  .l-section {
    margin-top: 30px;
  }
}

.l-section._no-padding {
  margin-top: 0;
}

@media (max-width: 767px) {
  .l-section._no-padding {
    margin-top: 0;
  }
}

.l-section .m-sub-heading:first-child {
  margin-top: 0;
}

.l-section .l-flex:last-child,
.l-section .m-figure-box:last-child {
  margin-bottom: 0;
}

.l-side {
  flex: 0 0 auto;
  width: 270px;
  margin-right: 40px;
}

@media (max-width: 1439px) {
  .l-side {
    padding-left: 10px;
    width: 220px;
  }
}

@media (max-width: 767px) {
  .l-side {
    width: 100%;
    margin-right: 0;
    padding-left: 0;
    margin-top: 100px;
  }
}

.l-side ul {
  list-style: none;
}

.l-side_list {
  margin: 0;
  padding: 0;
}

@media (max-width: 767px) {
  .l-side_list {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid;
    background-color: #f2f2f2;
  }
}

@media (max-width: 767px) {
  .l-side_list > li {
    flex: 0 0 50%;
    border-right: 1px solid;
    border-bottom: 1px solid;
  }
}

@media (max-width: 767px) {
  .l-side_list > li:nth-of-type(even) {
    border-right: 0;
  }
}

.l-side_item {
  position: relative;
  display: block;
  margin-bottom: 30px;
  padding-left: 1.5em;
  font-size: 1.125rem;
  font-weight: bold;
  color: #231815;
  line-height: 1;
}

@media (max-width: 767px) {
  .l-side_item {
    display: flex;
    height: 100%;
    align-items: center;
    background-color: #f2f2f2;
    margin-bottom: 0;
    font-size: 0.875rem;
    font-weight: bold;
    padding: 20px;
    line-height: 1;
  }
}

.l-side_item:visited {
  color: #231815;
}

.l-side_item::before {
  position: absolute;
  display: inline-block;
  content: "";
  left: 0;
  top: .5em;
  width: 20px;
  height: 1px;
  margin-right: 10px;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .l-side_item::before {
    display: none;
  }
}

.l-side_item._has-child {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .l-side_item._has-child {
    margin-bottom: 0;
  }
}

.l-side_item._active {
  color: #c00;
}

.l-side_child {
  padding: 0;
  margin: 0 0 30px 50px;
}

@media (max-width: 767px) {
  .l-side_child {
    display: none;
  }
}

.l-side_child a {
  display: block;
  margin-bottom: 16px;
  color: #231815;
  font-weight: bold;
}

.l-side_child a:visited {
  color: #231815;
}

.l-side._fixed .l-side_list {
  position: absolute;
}

@media (max-width: 767px) {
  .l-side._fixed .l-side_list {
    position: relative;
    top: 0 !important;
  }
}

.l-wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .l-wrapper {
    width: auto;
    margin: 0;
  }
}

.l-wrapper._flex {
  display: flex;
}

@media (max-width: 767px) {
  .l-wrapper._flex {
    flex-direction: column-reverse;
  }
}

@media (max-width: 767px) {
  .l-wrapper._has-subnav {
    display: flex;
    flex-direction: column-reverse;
  }
}

.m-banner {
  display: inline-block;
  margin: 0 auto;
}

.m-banner img {
  width: 100%;
}

.m-banner + .m-banner {
  margin-top: 15px;
}

.m-category {
  padding: .25em 1em;
  border-radius: 4px;
  border: 1px solid #231815;
  font-size: 0.875rem;
  line-height: 1;
}

.m-category._information {
  color: #008745;
  border-color: #008745;
}

.m-category._event {
  color: #882784;
  border-color: #882784;
}

.m-heading {
  background-color: #dbdbdb;
  text-align: center;
  font-size: 1.4375rem;
  letter-spacing: .34em;
  padding: .175em 0;
}

@media (max-width: 767px) {
  .m-heading {
    font-size: 1.125rem;
  }
}

.c-pagination {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

@media (max-width: 767px) {
  .c-pagination {
    align-items: center;
    margin-top: 30px;
  }
}

.c-pagination .page-numbers {
  display: inline-block;
  width: 50px;
  line-height: 50px;
  margin: 0 6px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #a8a8a8;
  transition: all .15s;
  color: #231815;
}

@media (max-width: 767px) {
  .c-pagination .page-numbers {
    width: 8vw;
    line-height: 8vw;
    margin: 0 3px;
  }
}

.c-pagination .page-numbers.current {
  background-color: #231815;
  border: 1px solid #231815;
  color: #fff;
  font-weight: bold;
}

.c-pagination .page-numbers:hover {
  text-decoration: none;
  background-color: #c00;
  border: 1px solid #c00;
  color: #fff;
}

.c-pagination .page-numbers.next, .c-pagination .page-numbers.prev {
  width: auto;
  border: 0;
  padding: 0 1em;
  margin: 0 15px;
  font-size: 1rem;
}

@media (max-width: 767px) {
  .c-pagination .page-numbers.next, .c-pagination .page-numbers.prev {
    font-size: 1rem;
    margin: 0 5px;
    padding: 0 .5em;
  }
}

.c-pagination .page-numbers.next:hover .m-icon span, .c-pagination .page-numbers.prev:hover .m-icon span {
  background-color: #fff;
}

.c-visual {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 270px;
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  .c-visual {
    height: 180px;
    margin-bottom: 40px;
  }
}

.c-visual div {
  padding: 15px 20px;
  background-color: #000;
  color: #fff;
  text-align: center;
}

@media (max-width: 767px) {
  .c-visual div {
    padding: 10px 15px;
  }
}

.c-visual_title {
  font-size: 2rem;
  font-weight: normal;
  line-height: 1;
  margin: 0 0 10px;
}

@media (max-width: 767px) {
  .c-visual_title {
    font-size: 1.5rem;
  }
}

.c-visual_text {
  margin: 0;
  font-size: 0.875rem;
  line-height: 1;
}

.p-contact {
  display: flex;
  align-items: center;
  max-width: 1000px;
  width: 100%;
  margin: 40px auto;
  padding: 40px 30px;
  background-color: #fff;
  text-align: center;
  border: 1px solid #a8a8a8;
}

@media (max-width: 767px) {
  .p-contact {
    flex-direction: column;
    width: auto;
    margin: 30px 4vw;
    padding: 20px;
  }
}

.p-contact_content {
  flex: 1 0 auto;
  text-align: center;
}

.p-contact_img {
  width: 300px;
}

@media (max-width: 767px) {
  .p-contact_img {
    width: 100%;
  }
}

.p-contact p {
  font-size: 1.125rem;
  font-weight: bold;
}

@media (max-width: 767px) {
  .p-contact p {
    margin-top: 0;
  }
}

.p-contact p a {
  display: inline-block;
  font-size: 2rem;
  color: #fff;
  background-color: #231815;
  border-radius: 1.5em;
  padding: 0 1em;
  transition: opacity .15s;
}

@media (max-width: 767px) {
  .p-contact p a {
    font-size: 1.5rem;
  }
}

.p-contact p a:hover {
  text-decoration: none;
  opacity: .75;
}

.p-footer {
  margin-top: 80px;
  border-top: 1px solid #a8a8a8;
  background-color: #fff;
  text-align: center;
}

@media (max-width: 767px) {
  .p-footer {
    margin-top: 40px;
  }
}

.p-footer_content {
  padding: 40px 0 15px;
}

@media (max-width: 767px) {
  .p-footer_content {
    padding: 30px 0 15px;
  }
}

.p-footer_content h3 {
  margin: 0;
  font-size: 1.3125rem;
  line-height: 1;
}

.p-footer_content h3 img {
  width: 159px;
}

.p-footer_content p {
  margin: 10px 0 20px;
  color: #666;
  font-size: 0.875rem;
}

@media (max-width: 767px) {
  .p-footer_content p {
    margin: 10px 0;
  }
}

.p-footer_copy {
  font-size: 0.6875rem;
  color: #666;
}

.p-footer_nav {
  display: flex;
}

@media (max-width: 767px) {
  .p-footer_nav {
    flex-direction: column;
  }
}

.p-footer_nav a {
  flex: 1;
  display: block;
  color: #fff;
  background-color: #000;
  line-height: 60px;
  transition: opacity .15s;
}

.p-footer_nav a:hover {
  opacity: .75;
  text-decoration: none;
}

.p-footer_nav a svg {
  display: inline-block;
  margin-right: 5px;
  max-height: 28px;
}

.p-footer_nav a._tel {
  border-right: 1px solid #fff;
}

@media (max-width: 767px) {
  .p-footer_nav a._tel {
    border-right: 0;
    border-bottom: 1px solid #fff;
  }
}

.p-footer_nav a._tel svg {
  width: 18px;
}

.p-footer_nav a._document {
  border-right: 1px solid #fff;
}

@media (max-width: 767px) {
  .p-footer_nav a._document {
    border-right: 0;
    border-bottom: 1px solid #fff;
  }
}

.p-footer_nav a._document svg {
  width: 24px;
}

.p-footer_nav a._contact {
  border-right: 1px solid #fff;
}

@media (max-width: 767px) {
  .p-footer_nav a._contact {
    border-right: 0;
    border-bottom: 1px solid #fff;
  }
}

.p-footer_nav a._contact svg {
  width: 28px;
}

.p-footer_nav a._top {
  position: relative;
  flex: 0 0 auto;
  width: 100px;
}

@media (max-width: 767px) {
  .p-footer_nav a._top {
    width: auto;
    height: 60px;
  }
}

.p-footer_nav a._top span {
  position: absolute;
  display: block;
  width: 24px;
  height: 1px;
  top: 50%;
  left: 50%;
  background-color: #fff;
}

.p-footer_nav a._top span:first-of-type {
  transform: rotate(-60deg);
  margin-left: -18px;
}

.p-footer_nav a._top span:last-of-type {
  transform: rotate(60deg);
  margin-left: -6px;
}

.p-form {
  max-width: 710px;
  width: 100%;
  margin: 30px auto 0;
}

.p-form_row {
  display: flex;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .p-form_row {
    flex-direction: column;
    margin-bottom: 20px;
    padding: 0 4vw;
  }
}

.p-form_label {
  flex: 1 0 auto;
  padding-top: 1em;
  font-weight: bold;
  font-size: 0.9375rem;
  letter-spacing: .05em;
}

.p-form_inputs {
  flex: 0 0 auto;
  width: 500px;
}

@media (max-width: 767px) {
  .p-form_inputs {
    width: 100%;
  }
}

.p-form_input, .p-form_textarea {
  width: 100%;
  padding: 1em;
  border: 0;
  background-color: #fff;
}

.p-form_required {
  font-size: 0.625rem;
  color: #b6272d;
}

.p-form_radios {
  flex: 0 0 auto;
  width: 500px;
  padding: 1em 0;
  line-height: 1;
}

@media (max-width: 767px) {
  .p-form_radios {
    width: 100%;
  }
}

.p-form_radios .mwform-radio-field-text {
  position: relative;
  display: inline-block;
  padding-left: 24px;
}

.p-form_radios .mwform-radio-field-text::before {
  position: absolute;
  display: block;
  content: "";
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 2px solid #000;
  border-radius: 50%;
  background-color: #fff;
}

.p-form_radios .mwform-radio-field-text::after {
  position: absolute;
  display: none;
  content: "";
  left: 5px;
  top: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #000;
}

.p-form_radios input[type="radio"]:checked + .mwform-radio-field-text::after {
  display: block;
}

.p-form_radio {
  display: none;
}

.p-form_footer {
  display: flex;
}

.p-form_btn {
  flex: 1;
  padding: 1em;
  margin: 10px;
  color: #fff;
  border: 0;
  background-color: #4d4d4d;
  font-size: 1.1875rem;
  font-weight: bold;
  line-height: 1;
}

.p-form_btn:visited {
  color: #fff;
}

.p-form_btn._submit {
  background-color: #000;
}

.p-form_complete {
  margin-top: 30px;
  font-size: 1.5rem;
  letter-spacing: .34em;
}

@media (max-width: 767px) {
  .p-form_complete {
    margin: 30px 4vw 0;
    font-size: 1.5rem;
    letter-spacing: .08em;
  }
}

.p-form_text {
  margin: 30px 0;
  text-align: center;
}

@media (max-width: 767px) {
  .p-form_text {
    margin: 30px 4vw;
  }
}

.mw_wp_form_confirm .p-form_label, .mw_wp_form_confirm .p-form_radios {
  padding-top: 0;
}

.mw_wp_form_confirm .contact-description p {
  display: none;
}

.mw_wp_form_complete {
  text-align: center;
}

.mw_wp_form_complete .contact-description p {
  display: none;
}

.p-header {
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 500;
  top: 0;
  left: 0;
}

.p-header_container {
  position: relative;
  height: 80px;
  display: flex;
  width: 1000px;
  margin: 0 auto;
}

@media (max-width: 1023px) {
  .p-header_container {
    width: auto;
    height: 60px;
  }
}

.p-header_logo {
  position: relative;
  margin: 20px auto 0 0;
}

@media (max-width: 1023px) {
  .p-header_logo {
    display: flex;
    align-items: center;
    flex: 1;
    height: 100%;
    margin: 0;
    position: relative;
    padding-left: 2.66667vw;
    background-color: #fff;
    z-index: 500;
  }
}

.p-header_logo a {
  display: block;
  font-size: 0.75rem;
  color: #231815;
  line-height: 1;
  font-weight: bold;
  letter-spacing: .1em;
  transition: all .15s;
}

.p-header_logo a:hover {
  text-decoration: none;
  opacity: .5;
}

.p-header_logo a:hover img {
  opacity: 1;
}

@media (max-width: 1023px) {
  .p-header_logo a span {
    vertical-align: middle;
  }
}

.p-header_logo img {
  display: block;
  width: 176px;
  margin-bottom: 6px;
}

@media (max-width: 1023px) {
  .p-header_logo img {
    display: inline-block;
    width: 120px;
    margin: 0 10px 0 0;
  }
}

.p-nav {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1023px) {
  .p-nav {
    position: fixed;
    flex-direction: column-reverse;
    right: -100%;
    width: 100%;
    height: 100%;
    padding: 60px 0 40px;
    background-color: #231815;
    z-index: 400;
    transition: all .15s;
    overflow-y: auto;
  }
}

.p-nav_container {
  flex: 1 1 auto;
}

@media (max-width: 1023px) {
  .p-nav_container {
    flex: 0;
  }
}

.p-nav_list {
  display: flex;
  list-style: none;
  height: 100%;
  margin: 0;
  padding: 0;
}

@media (max-width: 1023px) {
  .p-nav_list {
    margin-bottom: 30px;
    height: auto;
    flex-direction: column;
  }
}

.p-nav_link {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 .5em;
  margin: 0 5px;
  font-size: 0.875rem;
  color: #333;
  line-height: 1;
  text-align: center;
  left: .08em;
}

@media (max-width: 1023px) {
  .p-nav_link {
    color: #fff;
    margin-left: 0;
    padding: 1em 4vw;
    text-align: left;
    font-size: 1rem;
    border-bottom: 1px solid #dbdbdb;
  }
}

.p-nav_link:visited {
  color: #333;
}

@media (max-width: 1023px) {
  .p-nav_link:visited {
    color: #fff;
  }
}

.p-nav_link:hover, .p-nav_link._active {
  color: #333;
  text-decoration: none;
}

.p-nav_link:hover .p-nav_text::after, .p-nav_link._active .p-nav_text::after {
  opacity: 1;
}

.p-nav_text {
  position: relative;
  display: block;
  height: 100%;
}

.p-nav_text::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #231815;
  opacity: 0;
  transition: opacity .15s;
}

@media (max-width: 1023px) {
  .p-nav_text::after {
    width: 12px;
    height: 2px;
    background-color: #fff;
    opacity: 1;
    left: auto;
    right: 0;
    transform: rotate(-45deg);
  }
}

@media (max-width: 1023px) {
  .p-nav_text::before {
    position: absolute;
    content: "";
    width: 12px;
    height: 2px;
    bottom: 50%;
    right: 0;
    background-color: #fff;
    transform: rotate(45deg);
  }
}

.p-nav_sub {
  margin-left: auto;
  padding: 10px 0;
  text-align: right;
}

@media (max-width: 1023px) {
  .p-nav_sub {
    flex: 1;
    margin-left: 0;
    padding: 0 4vw;
  }
}

.p-nav_contact {
  display: inline-block;
  padding: .1em 1.5em;
  background-color: #dbdbdb;
  border-radius: 1em;
  color: #333;
  font-size: 0.75rem;
  font-weight: bold;
  transition: all .15s;
}

@media (max-width: 1023px) {
  .p-nav_contact {
    width: 100%;
    text-align: center;
    line-height: 36px;
    font-size: 1.125rem;
  }
}

.p-nav_contact:visited {
  color: #333;
}

.p-nav_contact:hover {
  text-decoration: none;
  background-color: #a8a8a8;
  color: #fff;
}

.p-nav_contact svg {
  width: 16px;
  height: 11.52px;
  margin-right: .5em;
}

@media (max-width: 1023px) {
  .p-nav_contact svg {
    width: 18px;
    height: auto;
  }
}

.p-nav_btn {
  display: none;
}

@media (max-width: 1023px) {
  .p-nav_btn {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 0;
    width: 60px;
    height: 60px;
    background-color: #231815;
    z-index: 600;
    transition: all .15s;
  }
}

.p-nav_icon {
  position: relative;
  display: block;
  width: 20px;
  height: 16px;
  padding: 0;
}

.p-nav_icon span {
  position: absolute;
  display: block;
  width: 20px;
  height: 2px;
  background-color: #fff;
  transform: translateY(-50%);
  transition: all .15s;
}

.p-nav_icon span:first-of-type {
  top: 0;
}

.p-nav_icon span:nth-of-type(2) {
  top: 50%;
}

.p-nav_icon span:last-of-type {
  top: 100%;
}

.about .c-visual {
  background: url("../images/about/visual.jpg") center no-repeat;
  background-size: cover;
}

.about .m-heading {
  margin-bottom: 0;
}

.about h2 {
  padding-left: 1em;
  font-size: 1.3125rem;
  letter-spacing: .34em;
  font-weight: normal;
}

.about-container {
  margin-bottom: 80px;
  padding: 30px 55px;
  border-top: 1px solid #a8a8a8;
  border-bottom: 1px solid #a8a8a8;
}

@media (max-width: 767px) {
  .about-container {
    padding: 18px;
  }
  .about-container br {
    display: none;
  }
}

.about-dl {
  border-top: 1px solid #a8a8a8;
  margin-bottom: 80px;
}

@media (max-width: 767px) {
  .about-dl {
    margin-bottom: 50px;
  }
}

.about-dl div {
  display: flex;
  padding: 24px;
  border-bottom: 1px solid #a8a8a8;
  letter-spacing: .34em;
}

@media (max-width: 767px) {
  .about-dl div {
    padding: 18px;
    flex-direction: column;
  }
}

.about-dl div dt {
  flex: 0 0 18%;
  margin: 0;
  padding: 0;
}

.about-dl div dd {
  flex: 1;
  margin: 0;
  padding: 0;
}

.about-map {
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  .about-map {
    margin-bottom: 30px;
  }
}

.about-map iframe {
  width: 100%;
  height: 530px;
}

@media (max-width: 767px) {
  .about-map iframe {
    height: 360px;
  }
}

.about-access {
  text-align: center;
}

.about-access h2 {
  margin: 0;
}

.about-access p {
  letter-spacing: .34em;
}

.archive .c-visual {
  background: url("../images/news/visual.jpg") center no-repeat;
  background-size: cover;
}

.archive_item {
  display: flex;
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #323333;
}

@media (max-width: 767px) {
  .archive_item {
    flex-direction: column;
  }
}

.archive_thumb {
  flex: 0 0 27%;
  margin-right: 35px;
  text-align: center;
}

@media (max-width: 767px) {
  .archive_thumb {
    margin: 0 0 15px;
  }
}

.archive_content {
  flex: 1 1 auto;
  word-break: break-all;
}

.archive_header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #323333;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

@media (max-width: 767px) {
  .archive_header {
    flex-direction: column;
    align-items: flex-start;
  }
}

.archive_header .m-category {
  flex: 0 0 auto;
  display: block;
  margin-left: 10px;
}

@media (max-width: 767px) {
  .archive_header .m-category {
    margin-left: 0;
  }
}

.archive_date {
  flex: 0 0 auto;
  font-size: 0.8125rem;
  color: #a8a8a8;
  margin-right: 15px;
}

.archive_title {
  font-size: 1rem;
  font-weight: bold;
  margin-right: auto;
}

.archive_title a {
  color: #333;
  word-break: break-all;
}

.archive_more {
  margin-top: 10px;
}

.archive_more a {
  color: #333;
  text-decoration: underline;
}

.archive_more a:visited {
  color: #333;
}

.archive_more a:hover {
  text-align: none;
}

.contact .c-visual {
  background: url("../images/contact/visual.jpg") center no-repeat;
  background-size: cover;
}

.contact-description {
  text-align: center;
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  .contact-description {
    margin: 0 4vw;
  }
}

.contact-description img {
  width: 50px;
}

.contact-description p {
  font-size: 0.9375rem;
  line-height: 2;
  letter-spacing: .34em;
}

.flow .c-visual {
  background: url("../images/flow/visual.jpg") center no-repeat;
  background-size: cover;
}

.flow-description {
  margin: 60px 0;
  font-size: 1.5rem;
  text-align: center;
  letter-spacing: .42em;
}

@media (max-width: 767px) {
  .flow-description {
    margin: 30px 4vw;
    font-size: 1rem;
    letter-spacing: .08em;
  }
}

.flow-container {
  display: flex;
  max-width: 936px;
  width: 100%;
  margin: 0 auto;
  flex-wrap: wrap;
}

.flow-section {
  flex: 1 0 1;
  max-width: 432px;
  width: 100%;
  height: 200px;
  margin: 16px;
  padding: 20px;
  background-color: #dbdbdb;
  font-size: 0.9375rem;
}

.flow-section h2 {
  margin: 0;
  line-height: 1;
  font-size: 1.625rem;
  font-weight: normal;
  letter-spacing: 0;
}

@media (max-width: 767px) {
  .flow-section h2 {
    font-size: 1.25rem;
  }
}

.flow-section h2 img {
  display: inline-block;
  height: 38px;
  margin-right: 14px;
}

@media (max-width: 767px) {
  .flow-section h2 img {
    height: 6.4vw;
    margin-right: 10px;
  }
}

.flow-section p {
  margin-bottom: 0;
  letter-spacing: 0;
}

.flow .p-contact {
  max-width: 900px;
}

.home-visual {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 720px;
  padding-bottom: 40px;
  background-color: #fff;
}

@media (max-width: 767px) {
  .home-visual {
    height: 360px;
    margin-bottom: 0;
  }
}

.home-visual p {
  position: relative;
  z-index: 300;
}

.home-visual p img {
  width: 920px;
}

@media (max-width: 767px) {
  .home-visual p img {
    width: 80vw;
  }
}

.home-slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.home-slider .sp-image {
  width: 100%;
}

.home-slider .sp-button {
  border-color: #a8a8a8;
  background-color: #a8a8a8;
}

.home-slider .sp-button.sp-selected-button {
  border-color: #000;
  background-color: #000;
}

.home-campaign {
  padding: 50px 0 0;
  text-align: center;
  background-color: #fff;
}

.home-news {
  background-color: #fff;
  padding: 50px 0;
}

@media (max-width: 1023px) {
  .home-news {
    padding: 50px 20px;
  }
}

@media (max-width: 767px) {
  .home-news {
    padding: 50px 0;
  }
}

.home-news .l-container {
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .home-news .l-container {
    display: block;
  }
}

.home-news-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.home-news-list li {
  display: flex;
  padding: 12px 0;
  border-bottom: 1px solid #dbdbdb;
}

@media (max-width: 767px) {
  .home-news-list li {
    flex-direction: column;
    padding: 20px 0;
  }
}

.home-news-content {
  flex: 1;
}

.home-news-heading {
  flex: 0 0 225px;
  line-height: 1;
}

@media (max-width: 767px) {
  .home-news-heading {
    margin-bottom: 10px;
  }
}

.home-news-heading h2 {
  margin: 0;
}

.home-news-heading h2 img {
  width: 160px;
}

@media (max-width: 767px) {
  .home-news-heading h2 img {
    width: 42.66667vw;
  }
}

.home-news-heading p {
  font-size: 0.75rem;
  letter-spacing: 0;
}

@media (max-width: 767px) {
  .home-news-heading p {
    font-size: 0.875rem;
    margin-bottom: 0;
  }
}

.home-news-date {
  flex: 0 0 230px;
  font-size: 0.875rem;
}

@media (max-width: 767px) {
  .home-news-date {
    flex: 1;
    margin-bottom: 10px;
  }
}

.home-news-date .m-category {
  margin-left: 1em;
}

.home-news-title {
  flex: 1 1 1;
  font-size: 0.875rem;
  color: #333;
  text-decoration: underline;
}

.home-news-title:visited {
  color: #333;
}

.home-news-title:hover {
  text-decoration: none;
}

.home-news-footer {
  text-align: right;
}

@media (max-width: 767px) {
  .home-news-footer {
    padding-top: 10px;
  }
}

.home-news-footer a {
  font-size: 0.875rem;
  color: #333;
}

.home-make {
  border-top: 1px solid #a8a8a8;
  padding-top: 35px;
}

.home-make h2 {
  margin: 0 0 50px;
  font-size: 2rem;
  font-weight: normal;
  text-align: center;
  line-height: 1;
}

@media (max-width: 767px) {
  .home-make h2 {
    margin-bottom: 30px;
    font-size: 1.5rem;
  }
}

.home-make-container {
  display: flex;
}

.home-make-container a {
  display: block;
  flex: 1 0 50%;
  transition: opacity .15s;
}

.home-make-container a:hover {
  text-decoration: none;
  color: #fff;
  opacity: .75;
}

.home-make-container a:hover img {
  opacity: 1;
}

.home-make-container a img {
  width: 100%;
}

.home-make-container a h3 {
  margin: 0;
  background-color: #4d4d4d;
  color: #fff;
  text-align: center;
  font-size: 1.375rem;
  line-height: 52px;
  font-weight: normal;
}

@media (max-width: 767px) {
  .home-make-container a h3 {
    font-size: 1.125rem;
    line-height: 36px;
  }
}

.home-make-container a:first-of-type h3 {
  background-color: #231815;
}

.home-simple {
  padding: 85px 20px 35px;
}

@media (max-width: 767px) {
  .home-simple {
    padding: 40px 0;
  }
}

.home-simple-container {
  display: flex;
}

@media (max-width: 767px) {
  .home-simple-container {
    flex-direction: column;
  }
}

.home-simple-img {
  width: 62%;
  margin-right: 10px;
}

@media (max-width: 767px) {
  .home-simple-img {
    width: 100%;
    margin: 0 0 15px;
  }
}

.home-simple-img img {
  width: 100%;
}

.home-simple-content {
  display: flex;
  flex-direction: column;
  width: 340px;
  margin-left: auto;
}

@media (max-width: 767px) {
  .home-simple-content {
    width: auto;
    margin: 0;
    text-align: center;
  }
}

.home-simple-content h2 {
  margin: 0;
  letter-spacing: .05em;
}

.home-simple-content p {
  font-size: 0.875rem;
  line-height: 2;
  letter-spacing: 0;
}

@media (max-width: 767px) {
  .home-simple-content p {
    font-size: 0.75rem;
    margin-bottom: 30px;
  }
}

.home-simple-btn {
  display: block;
  padding: 20px 0;
  margin-top: auto;
  background-color: #231815;
  text-align: center;
  color: #fff;
  transition: all .15s;
}

@media (max-width: 767px) {
  .home-simple-btn {
    width: 100%;
  }
}

.home-simple-btn:visited {
  color: #fff;
}

.home-simple-btn:hover {
  opacity: .75;
  color: #fff;
  text-decoration: none;
}

.home-simple-btn:hover img {
  opacity: 1;
}

.home-simple-btn div {
  width: 270px;
  margin: 0 auto 12px;
  padding: 8px 0 14px;
  border: 1px solid #fff;
}

@media (max-width: 767px) {
  .home-simple-btn div {
    width: 66.66667vw;
    padding: 10px 0;
  }
}

.home-simple-btn div img {
  width: 228px;
}

@media (max-width: 767px) {
  .home-simple-btn div img {
    width: 80%;
  }
}

.home-simple-btn p {
  font-size: 0.875rem;
  margin: 0;
}

@media (max-width: 767px) {
  .home-simple-btn p {
    line-height: 1;
  }
}

.home-simple-images {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 35px;
}

.home-simple-images div {
  flex: 0 0 23%;
  margin: 20px auto 0 0;
}

@media (max-width: 767px) {
  .home-simple-images div {
    flex: 0 0 48%;
  }
}

.home-simple-images div:nth-of-type(4), .home-simple-images div:nth-of-type(8) {
  margin-right: 0;
}

@media (max-width: 767px) {
  .home-simple-images div:nth-of-type(even) {
    margin-right: 0;
  }
}

.home-simple-images div img {
  width: 100%;
}

.home-exhibition {
  padding: 0 20px 90px;
}

@media (max-width: 767px) {
  .home-exhibition {
    padding: 0 0 50px;
  }
}

.home-exhibition-container {
  display: flex;
  padding: 30px 30px 30px 45px;
  background-color: #fff;
  border: 1px solid #000;
}

@media (max-width: 767px) {
  .home-exhibition-container {
    padding: 20px;
    flex-direction: column;
  }
}

.home-exhibition-content {
  margin-right: 10px;
}

@media (max-width: 767px) {
  .home-exhibition-content {
    text-align: center;
    margin-bottom: 20px;
  }
}

.home-exhibition-content h2 {
  margin: 0;
  font-weight: normal;
  font-size: 1.9375rem;
}

.home-exhibition-content p {
  letter-spacing: .26em;
}

@media (max-width: 767px) {
  .home-exhibition-content p {
    font-size: 0.875rem;
  }
}

.home-exhibition-content p img {
  display: inline-block;
  width: 175px;
  margin-top: 10px;
}

@media (max-width: 767px) {
  .home-exhibition-content p img {
    width: 64vw;
  }
}

.home-exhibition-map {
  flex: 0 0 auto;
  width: 415px;
  margin-left: auto;
}

@media (max-width: 767px) {
  .home-exhibition-map {
    width: 100%;
    height: 48vw;
  }
}

.home-exhibition-map iframe {
  width: 100%;
  height: 100%;
}

.home-access {
  padding-bottom: 60px;
}

@media (max-width: 767px) {
  .home-access {
    padding-bottom: 40px;
  }
}

.home-access h2 {
  margin: 0;
  font-size: 1.3125rem;
  text-align: center;
  letter-spacing: .1em;
  line-height: 1;
}

.home-access-container {
  display: flex;
  padding-top: 60px;
}

@media (max-width: 767px) {
  .home-access-container {
    flex-direction: column;
    padding-top: 30px;
  }
}

.home-access-container div {
  flex: 0 0 49%;
}

.home-access-container div img {
  width: 100%;
}

.home-access-map {
  margin-left: auto;
}

@media (max-width: 767px) {
  .home-access-map {
    margin: 10px 0 0;
    height: 64vw;
  }
}

.home-access-map iframe {
  width: 100%;
  height: 100%;
}

.home-zeh {
  padding: 0 20px 35px;
  margin-top: -18px;
}

@media (max-width: 767px) {
  .home-zeh {
    padding: 0 0 40px 0;
    margin-top: -23px;
  }
}

.home-zeh-banner p {
  display: block;
  font-size: 2.5rem;
  letter-spacing: 6px;
  color: #fff;
  text-align: center;
  background-color: #96C165;
  border-radius: 10px;
  transition: opacity 0.15s;
  padding: 15px 0;
  margin: 0;
}

@media (max-width: 767px) {
  .home-zeh-banner p {
    font-size: min(4.9vw, 32px);
    letter-spacing: 3px;
    padding: 20px 0;
  }
}

.home-zeh-banner:hover {
  color: #fff;
  text-decoration: none;
  opacity: 0.75;
}

.make .c-visual {
  background: url("../images/make/visual.jpg") center no-repeat;
  background-size: cover;
}

.make-section {
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .make-section {
    margin-bottom: 30px;
  }
}

.make-section + .make-section {
  margin-top: 100px;
}

@media (max-width: 767px) {
  .make-section + .make-section {
    margin-top: 50px;
  }
}

.make-section .l-flex_img {
  flex: 1 0 auto;
}

.make-section .l-flex_img img + a {
  display: block;
  margin-top: 40px;
}

.make-section .l-flex_img + .l-flex_content {
  margin-left: 40px;
}

@media (max-width: 767px) {
  .make-section .l-flex_img + .l-flex_content {
    margin-left: 0;
    margin-top: 20px;
  }
}

.make-section .l-flex_content + .l-flex_img {
  margin-left: 40px;
}

@media (max-width: 767px) {
  .make-section .l-flex_content + .l-flex_img {
    margin-top: 20px;
    margin-left: 0;
  }
}

.make-section p {
  margin: 0;
  letter-spacing: .22em;
  line-height: 2;
}

@media (max-width: 767px) {
  .make-section p {
    letter-spacing: .08em;
    font-size: 0.875rem;
  }
}

.real-estate-container {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  .real-estate-container {
    flex-direction: column;
  }
}

.real-estate-container + .real-estate-container {
  margin-top: 60px;
}

@media (max-width: 767px) {
  .real-estate-container + .real-estate-container {
    margin-top: 0;
  }
}

.real-estate-box {
  max-width: 428px;
  width: 47.55556%;
  margin-right: auto;
  margin-top: 40px;
}

@media (max-width: 767px) {
  .real-estate-box {
    max-width: none;
    width: 100%;
    margin-top: 20px;
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.real-estate-box:nth-of-type(even) {
  margin-right: 0;
}

.real-estate-box img {
  width: 100%;
}

.real-estate-box h3 {
  margin: 15px 0;
  font-size: 1rem;
  letter-spacing: .34em;
  line-height: 1;
}

.real-estate-box h4 {
  margin: 0 0 20px;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1;
}

.real-estate-area {
  margin: 1em 0;
  font-size: 0.9375rem;
  letter-spacing: .34em;
}

.real-estate-footer {
  display: flex;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: .34em;
  text-align: center;
}

.real-estate-detail {
  display: block;
  flex: 1;
  margin-right: 24px;
  padding: .5em 0;
  background-color: #c1c1c1;
  color: #000;
  transition: all .15s;
}

.real-estate-detail:visited {
  color: #000;
}

.real-estate-detail:hover {
  color: #000;
  opacity: .75;
  text-decoration: none;
}

.real-estate-contact {
  display: block;
  flex: 1;
  padding: .5em 0;
  background-color: #9b7c52;
  color: #fff;
  transition: all .15s;
}

.real-estate-contact:visited {
  color: #fff;
}

.real-estate-contact:hover {
  color: #fff;
  opacity: .75;
  text-decoration: none;
}

.post-type-archive-real-estate .c-visual {
  background: url("../images/real-estate/visual.jpg") center no-repeat;
  background-size: cover;
}

.post-type-archive-real-estate .l-container + .l-container {
  margin-top: 70px !important;
}

@media (max-width: 767px) {
  .post-type-archive-real-estate .l-container + .l-container {
    margin-top: 40px !important;
  }
}

.simple-note .c-visual {
  background: url("../images/simple-note/visual.jpg") center no-repeat;
  background-size: cover;
}

.simple-note-description {
  margin: 50px 0;
  font-size: 1.3125rem;
  text-align: center;
  letter-spacing: .34em;
}

@media (max-width: 767px) {
  .simple-note-description {
    margin: 30px 4vw;
    font-size: 1rem;
    letter-spacing: .08em;
  }
  .simple-note-description br {
    display: none;
  }
}

.simple-note-main {
  height: 1125px;
  background: url("../images/simple-note/img-1.jpg") center no-repeat;
  background-size: cover;
  color: #fff;
}

@media (max-width: 767px) {
  .simple-note-main {
    height: auto;
    padding-bottom: 100px;
  }
}

.simple-note-container {
  display: flex;
  align-items: center;
  padding-top: 100px;
  margin: 0 0 460px;
}

@media (max-width: 767px) {
  .simple-note-container {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 40px;
    margin-bottom: 40px;
  }
}

.simple-note-container h2 {
  margin-right: auto;
  font-size: 1.75rem;
  letter-spacing: .34em;
}

@media (max-width: 767px) {
  .simple-note-container h2 {
    font-size: 1.25rem;
  }
}

.simple-note-container p {
  font-size: 1.0625rem;
  letter-spacing: .34em;
}

@media (max-width: 767px) {
  .simple-note-container p {
    font-size: 0.875rem;
    letter-spacing: .08em;
  }
}

.simple-note-text {
  font-size: 1.75rem;
  letter-spacing: .34em;
}

@media (max-width: 767px) {
  .simple-note-text {
    font-size: 1.25rem;
    letter-spacing: .08em;
  }
}

.simple-note-gallery {
  padding-top: 70px;
}

@media (max-width: 767px) {
  .simple-note-gallery {
    padding-top: 40px;
  }
}

.simple-note-gallery h3 {
  margin: 0 0 45px;
  text-align: center;
  font-size: 1.75rem;
  font-weight: normal;
  letter-spacing: .34em;
}

@media (max-width: 767px) {
  .simple-note-gallery h3 {
    font-size: 1.25rem;
    margin-bottom: 30px;
    letter-spacing: .1em;
  }
}

.simple-note-gallery-container {
  display: flex;
  flex-wrap: wrap;
}

.simple-note-gallery-container a, .simple-note-gallery-container div {
  display: block;
  flex: 0 0 31.2%;
  margin-right: auto;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .simple-note-gallery-container a, .simple-note-gallery-container div {
    flex: 0 0 49%;
    margin-bottom: 15px;
  }
}

.simple-note-gallery-container a:nth-of-type(3n), .simple-note-gallery-container div:nth-of-type(3n) {
  margin-right: 0;
}

@media (max-width: 767px) {
  .simple-note-gallery-container a:nth-of-type(3n), .simple-note-gallery-container div:nth-of-type(3n) {
    margin-right: auto;
  }
}

@media (max-width: 767px) {
  .simple-note-gallery-container a:nth-of-type(even), .simple-note-gallery-container div:nth-of-type(even) {
    margin-right: 0;
  }
}

.simple-note-exhibition {
  display: flex;
  align-items: center;
  padding: 30px 30px 30px 40px;
  margin-bottom: 40px;
  border: 1px solid #333;
  background-color: #fff;
}

@media (max-width: 767px) {
  .simple-note-exhibition {
    flex-direction: column;
    padding: 20px;
  }
}

.simple-note-exhibition-container h3 {
  margin: 0;
  font-size: 1.3125rem;
  letter-spacing: .34em;
}

@media (max-width: 767px) {
  .simple-note-exhibition-container h3 {
    font-size: 1.125rem;
    letter-spacing: .08em;
  }
}

.simple-note-exhibition-container h3 img {
  display: inline-block;
  margin-left: 10px;
  width: 160px;
}

.simple-note-exhibition-container p {
  margin: 1em 0 0;
  font-size: 0.9375rem;
  letter-spacing: .34em;
}

@media (max-width: 767px) {
  .simple-note-exhibition-container p {
    font-size: 0.875rem;
    letter-spacing: .08em;
  }
}

.simple-note-exhibition-img {
  flex: 0 0 220px;
  margin-left: 20px;
}

@media (max-width: 767px) {
  .simple-note-exhibition-img {
    flex: 1 0 auto;
    margin: 20px 0 0;
  }
}

.single .c-visual {
  background: url("../images/news/visual.jpg") center no-repeat;
  background-size: cover;
}

.single_container {
  display: flex;
}

@media (max-width: 767px) {
  .single_container {
    flex-direction: column;
  }
}

.single_main {
  flex: 1 1 auto;
  max-width: 680px;
}

@media (max-width: 767px) {
  .single_main {
    max-width: none;
  }
}

.single_side {
  flex: 1 0 auto;
  margin-left: 40px;
  letter-spacing: .34em;
}

@media (max-width: 767px) {
  .single_side {
    margin: 40px 0 0;
  }
}

.single_side h3 {
  margin: 0 0 .5em;
  font-size: 0.9375rem;
}

.single_list {
  margin: 0;
  padding: 0;
  list-style: none;
  border-top: 1px solid #323333;
}

.single_list li {
  border-bottom: 1px solid #323333;
}

.single_list li a {
  display: block;
  padding: .5em 0;
  color: #231815;
}

.single_thumb {
  flex: 0 0 27%;
  text-align: center;
}

@media (max-width: 767px) {
  .single_thumb {
    margin: 0 0 15px;
  }
}

.single_content {
  word-break: break-all;
}

.single_content div {
  max-width: 100%;
}

.single_content img {
  max-width: 100%;
}

.single_content blockquote {
  background-color: #fff;
  padding: 15px;
}

.single_content pre {
  max-width: 100%;
  word-break: break-all;
  white-space: pre-wrap;
}

.single_header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #323333;
  margin-bottom: 40px;
  padding-bottom: 15px;
}

@media (max-width: 767px) {
  .single_header {
    flex-direction: column;
    align-items: flex-start;
  }
}

.single_header .m-category {
  flex: 0 0 auto;
  display: block;
  margin-left: 10px;
}

@media (max-width: 767px) {
  .single_header .m-category {
    margin-left: 0;
  }
}

.single_date {
  font-size: 0.8125rem;
}

.single_title {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
  margin-right: auto;
}

.single_title a {
  color: #333;
  word-break: break-all;
}

.single_footer {
  display: flex;
  font-size: 0.875rem;
}

.single_footer ._prev {
  margin-right: auto;
}

.single_footer ._prev a {
  color: #333;
}

.single_footer ._next {
  margin-left: auto;
}

.single_footer ._next a {
  color: #333;
}

.staff .c-visual {
  background: url("../images/staff/visual.jpg") center no-repeat;
  background-size: cover;
}

.staff-section {
  display: flex;
}

@media (max-width: 767px) {
  .staff-section {
    flex-direction: column;
  }
}

.staff-section + .staff-section {
  margin-top: 80px;
}

@media (max-width: 767px) {
  .staff-section + .staff-section {
    margin-top: 40px;
  }
}

.staff-img {
  flex: 0 0 43%;
}

.staff-content {
  flex: 1;
  margin-left: 35px;
}

@media (max-width: 767px) {
  .staff-content {
    margin: 20px 0 0;
  }
}

.staff-content h2 {
  display: flex;
  align-items: flex-end;
  margin: 0 0 15px;
  padding-bottom: 15px;
  font-size: 1.6875rem;
  border-bottom: 1px solid #040000;
  letter-spacing: .34em;
  line-height: 1;
}

@media (max-width: 767px) {
  .staff-content h2 {
    font-size: 1.5rem;
    letter-spacing: .1em;
  }
}

.staff-content h3 {
  margin: 0 0 20px;
  font-weight: bold;
  color: #816239;
  letter-spacing: .34em;
  line-height: 1;
}

@media (max-width: 767px) {
  .staff-content h3 {
    letter-spacing: .1em;
  }
}

.staff-en {
  margin-left: auto;
  font-size: 1.1875rem;
  color: #bdbebe;
}

.staff-comment {
  font-size: 0.9375rem;
}

.staff-comment h4 {
  margin: 0;
}

.staff-comment p:first-of-type {
  margin-top: 0;
}

.staff-comment p:last-of-type {
  margin-bottom: 0;
}

.zeh .c-visual {
  background: url(../images/zeh/visual.jpg) center no-repeat;
  background-size: cover;
}

.zeh .c-visual div {
  line-height: 1.4;
  background-color: transparent;
  color: transparent;
  background: url(../images/zeh/zeh-title.png) center no-repeat;
  background-size: contain;
  width: 90%;
  max-width: 600px;
}

.zeh .c-visual_title {
  margin: 0;
}

.zeh-description {
  margin: 60px 0;
  font-size: 2rem;
  text-align: center;
  letter-spacing: 0.03em;
}

@media (max-width: 767px) {
  .zeh-description {
    margin: 30px 4vw;
    font-size: 24px;
    letter-spacing: 0.08em;
  }
}

.zeh-container {
  max-width: 780px;
  width: 100%;
  padding: 0 32px;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .zeh-container {
    padding: 0 16px;
  }
}

.zeh-section + .zeh-section {
  margin-top: 90px;
}

@media (max-width: 767px) {
  .zeh-section + .zeh-section {
    margin-top: 60px;
  }
}

.zeh-section h2, .zeh-section h3 {
  margin: 0;
  line-height: 1;
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: 0;
  text-align: center;
}

@media (max-width: 767px) {
  .zeh-section h2, .zeh-section h3 {
    font-size: 18px;
  }
}

.zeh-section-image {
  margin: 25px 0 0 0;
}

.zeh-section p {
  font-size: 0.875rem;
  letter-spacing: 0;
  margin-top: 20px;
  margin-bottom: 0;
}

.zeh-box {
  height: 147px;
  background-color: #E8E8E8;
  border: 1px solid rgba(112, 112, 112, 0.73);
  margin-top: 90px;
}

@media (max-width: 767px) {
  .zeh-box {
    height: 70px;
    margin-top: 60px;
  }
}

.zeh-section .zeh-section-copy {
  font-weight: 700;
  font-size: 2.15rem;
  line-height: 1.45;
  color: #00AC4D;
  text-align: center;
}

@media (max-width: 767px) {
  .zeh-section .zeh-section-copy {
    font-size: 2.0rem;
  }
}

.zeh-section-logo {
  max-width: 300px;
  margin: 24px auto -32px auto;
}

@media (max-width: 1023px) {
  .p-header._open .p-nav {
    right: 0;
  }
  .p-header._open .p-nav_btn {
    background-color: #231815;
  }
  .p-header._open .p-nav_icon span {
    transform: translateY(-50%);
  }
  .p-header._open .p-nav_icon span:first-of-type {
    top: 50%;
    transform: rotate(-45deg);
  }
  .p-header._open .p-nav_icon span:nth-of-type(2) {
    opacity: 0;
  }
  .p-header._open .p-nav_icon span:last-of-type {
    top: 50%;
    transform: rotate(45deg);
  }
}

.u_bg-text {
  background-color: #333 !important;
}

.u_bg-text-link {
  background-color: #f40000 !important;
}

.u_bg-text-link-hover {
  background-color: #c00 !important;
}

.u_bg-text-link-visited {
  background-color: #c00 !important;
}

.u_bg-white {
  background-color: #fff !important;
}

.u_bg-black {
  background-color: #231815 !important;
}

.u_bg-bg {
  background-color: #f6f6f6 !important;
}

.u_bg-thin-gray {
  background-color: #f6f6f6 !important;
}

.u_bg-hilight-gray {
  background-color: #dbdbdb !important;
}

.u_bg-light-gray {
  background-color: #a8a8a8 !important;
}

.u_bg-gray {
  background-color: #4d4d4d !important;
}

.u_bg-information {
  background-color: #008745 !important;
}

.u_bg-event {
  background-color: #882784 !important;
}

.u_bg-red {
  background-color: #c00 !important;
}

@media (max-width: 479px) {
  .u_bp-hide-xs {
    display: none !important;
  }
}

@media (max-width: 479px) {
  .u_bp-hide-lte-xs {
    display: none !important;
  }
}

.u_bp-hide-gte-xs {
  display: none !important;
}

@media (max-width: 479px) {
  .u_bp-show-xs-block {
    display: block !important;
  }
}

@media (max-width: 479px) {
  .u_bp-show-lte-xs-block {
    display: block !important;
  }
}

.u_bp-show-gte-xs-block {
  display: block !important;
}

@media (max-width: 479px) {
  .u_bp-show-xs-inline {
    display: inline !important;
  }
}

@media (max-width: 479px) {
  .u_bp-show-lte-xs-inline {
    display: inline !important;
  }
}

.u_bp-show-gte-xs-inline {
  display: inline !important;
}

@media (max-width: 479px) {
  .u_bp-show-xs-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 479px) {
  .u_bp-show-lte-xs-inline-block {
    display: inline-block !important;
  }
}

.u_bp-show-gte-xs-inline-block {
  display: inline-block !important;
}

@media (min-width: 480px) and (max-width: 767px) {
  .u_bp-hide-sm {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .u_bp-hide-lte-sm {
    display: none !important;
  }
}

@media (min-width: 480px) {
  .u_bp-hide-gte-sm {
    display: none !important;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .u_bp-show-sm-block {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .u_bp-show-lte-sm-block {
    display: block !important;
  }
}

@media (min-width: 480px) {
  .u_bp-show-gte-sm-block {
    display: block !important;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .u_bp-show-sm-inline {
    display: inline !important;
  }
}

@media (max-width: 767px) {
  .u_bp-show-lte-sm-inline {
    display: inline !important;
  }
}

@media (min-width: 480px) {
  .u_bp-show-gte-sm-inline {
    display: inline !important;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .u_bp-show-sm-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 767px) {
  .u_bp-show-lte-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 480px) {
  .u_bp-show-gte-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u_bp-hide-md {
    display: none !important;
  }
}

@media (max-width: 1023px) {
  .u_bp-hide-lte-md {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .u_bp-hide-gte-md {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u_bp-show-md-block {
    display: block !important;
  }
}

@media (max-width: 1023px) {
  .u_bp-show-lte-md-block {
    display: block !important;
  }
}

@media (min-width: 768px) {
  .u_bp-show-gte-md-block {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u_bp-show-md-inline {
    display: inline !important;
  }
}

@media (max-width: 1023px) {
  .u_bp-show-lte-md-inline {
    display: inline !important;
  }
}

@media (min-width: 768px) {
  .u_bp-show-gte-md-inline {
    display: inline !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u_bp-show-md-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 1023px) {
  .u_bp-show-lte-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) {
  .u_bp-show-gte-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .u_bp-hide-lg {
    display: none !important;
  }
}

@media (max-width: 1439px) {
  .u_bp-hide-lte-lg {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .u_bp-hide-gte-lg {
    display: none !important;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .u_bp-show-lg-block {
    display: block !important;
  }
}

@media (max-width: 1439px) {
  .u_bp-show-lte-lg-block {
    display: block !important;
  }
}

@media (min-width: 1024px) {
  .u_bp-show-gte-lg-block {
    display: block !important;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .u_bp-show-lg-inline {
    display: inline !important;
  }
}

@media (max-width: 1439px) {
  .u_bp-show-lte-lg-inline {
    display: inline !important;
  }
}

@media (min-width: 1024px) {
  .u_bp-show-gte-lg-inline {
    display: inline !important;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .u_bp-show-lg-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 1439px) {
  .u_bp-show-lte-lg-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1024px) {
  .u_bp-show-gte-lg-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1440px) {
  .u_bp-hide-xl {
    display: none !important;
  }
}

.u_bp-hide-lte-xl {
  display: none !important;
}

@media (min-width: 1440px) {
  .u_bp-hide-gte-xl {
    display: none !important;
  }
}

@media (min-width: 1440px) {
  .u_bp-show-xl-block {
    display: block !important;
  }
}

.u_bp-show-lte-xl-block {
  display: block !important;
}

@media (min-width: 1440px) {
  .u_bp-show-gte-xl-block {
    display: block !important;
  }
}

@media (min-width: 1440px) {
  .u_bp-show-xl-inline {
    display: inline !important;
  }
}

.u_bp-show-lte-xl-inline {
  display: inline !important;
}

@media (min-width: 1440px) {
  .u_bp-show-gte-xl-inline {
    display: inline !important;
  }
}

@media (min-width: 1440px) {
  .u_bp-show-xl-inline-block {
    display: inline-block !important;
  }
}

.u_bp-show-lte-xl-inline-block {
  display: inline-block !important;
}

@media (min-width: 1440px) {
  .u_bp-show-gte-xl-inline-block {
    display: inline-block !important;
  }
}

.u_cf {
  overflow: hidden;
}

.u_cf-legacy::before, .u_cf-legacy::after {
  display: table;
  content: " ";
}

.u_cf-legacy::after {
  clear: both;
}

.u_dp-none {
  display: none !important;
}

.u_dp-block {
  display: block !important;
}

.u_dp-inline {
  display: inline !important;
}

.u_dp-inline-block {
  display: inline-block !important;
}

.u_flip-horizontal {
  transform: scale(-1, 1);
}

.u_flip-vertical {
  transform: scale(1, -1);
}

.u_fw-100 {
  font-weight: 100 !important;
}

.u_fw-200 {
  font-weight: 200 !important;
}

.u_fw-300 {
  font-weight: 300 !important;
}

.u_fw-400 {
  font-weight: 400 !important;
}

.u_fw-500 {
  font-weight: 500 !important;
}

.u_fw-600 {
  font-weight: 600 !important;
}

.u_fw-700 {
  font-weight: 700 !important;
}

.u_fw-800 {
  font-weight: 800 !important;
}

.u_fw-900 {
  font-weight: 900 !important;
}

.u_fw-normal {
  font-weight: normal !important;
}

.u_fw-bold {
  font-weight: bold !important;
}

.u_ls-5 {
  letter-spacing: 0.05em !important;
}

.u_ls-10 {
  letter-spacing: 0.1em !important;
}

.u_ls-15 {
  letter-spacing: 0.15em !important;
}

.u_ls-20 {
  letter-spacing: 0.2em !important;
}

.u_ls-25 {
  letter-spacing: 0.25em !important;
}

.u_ls-30 {
  letter-spacing: 0.3em !important;
}

.u_ls-35 {
  letter-spacing: 0.35em !important;
}

.u_ls-40 {
  letter-spacing: 0.4em !important;
}

.u_ls-45 {
  letter-spacing: 0.45em !important;
}

.u_ls-50 {
  letter-spacing: 0.5em !important;
}

.u_ls-minus5 {
  letter-spacing: -0.05em !important;
}

.u_ls-minus10 {
  letter-spacing: -0.1em !important;
}

.u_ls-minus15 {
  letter-spacing: -0.15em !important;
}

.u_ls-minus20 {
  letter-spacing: -0.2em !important;
}

.u_ls-minus25 {
  letter-spacing: -0.25em !important;
}

.u_ls-minus30 {
  letter-spacing: -0.3em !important;
}

.u_ls-minus35 {
  letter-spacing: -0.35em !important;
}

.u_ls-minus40 {
  letter-spacing: -0.4em !important;
}

.u_ls-minus45 {
  letter-spacing: -0.45em !important;
}

.u_ls-minus50 {
  letter-spacing: -0.5em !important;
}

.u_ls-normal {
  letter-spacing: normal !important;
}

.u_m-0 {
  margin: 0px !important;
}

.u_my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.u_mx-0 {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.u_mt-0 {
  margin-top: 0px !important;
}

.u_mr-0 {
  margin-right: 0px !important;
}

.u_mb-0 {
  margin-bottom: 0px !important;
}

.u_ml-0 {
  margin-left: 0px !important;
}

.u_m-5 {
  margin: 5px !important;
}

.u_my-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.u_mx-5 {
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.u_mt-5 {
  margin-top: 5px !important;
}

.u_mr-5 {
  margin-right: 5px !important;
}

.u_mb-5 {
  margin-bottom: 5px !important;
}

.u_ml-5 {
  margin-left: 5px !important;
}

.u_m-10 {
  margin: 10px !important;
}

.u_my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.u_mx-10 {
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.u_mt-10 {
  margin-top: 10px !important;
}

.u_mr-10 {
  margin-right: 10px !important;
}

.u_mb-10 {
  margin-bottom: 10px !important;
}

.u_ml-10 {
  margin-left: 10px !important;
}

.u_m-15 {
  margin: 15px !important;
}

.u_my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.u_mx-15 {
  margin-right: 15px !important;
  margin-left: 15px !important;
}

.u_mt-15 {
  margin-top: 15px !important;
}

.u_mr-15 {
  margin-right: 15px !important;
}

.u_mb-15 {
  margin-bottom: 15px !important;
}

.u_ml-15 {
  margin-left: 15px !important;
}

.u_m-20 {
  margin: 20px !important;
}

.u_my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.u_mx-20 {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.u_mt-20 {
  margin-top: 20px !important;
}

.u_mr-20 {
  margin-right: 20px !important;
}

.u_mb-20 {
  margin-bottom: 20px !important;
}

.u_ml-20 {
  margin-left: 20px !important;
}

.u_m-25 {
  margin: 25px !important;
}

.u_my-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.u_mx-25 {
  margin-right: 25px !important;
  margin-left: 25px !important;
}

.u_mt-25 {
  margin-top: 25px !important;
}

.u_mr-25 {
  margin-right: 25px !important;
}

.u_mb-25 {
  margin-bottom: 25px !important;
}

.u_ml-25 {
  margin-left: 25px !important;
}

.u_m-30 {
  margin: 30px !important;
}

.u_my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.u_mx-30 {
  margin-right: 30px !important;
  margin-left: 30px !important;
}

.u_mt-30 {
  margin-top: 30px !important;
}

.u_mr-30 {
  margin-right: 30px !important;
}

.u_mb-30 {
  margin-bottom: 30px !important;
}

.u_ml-30 {
  margin-left: 30px !important;
}

.u_m-35 {
  margin: 35px !important;
}

.u_my-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.u_mx-35 {
  margin-right: 35px !important;
  margin-left: 35px !important;
}

.u_mt-35 {
  margin-top: 35px !important;
}

.u_mr-35 {
  margin-right: 35px !important;
}

.u_mb-35 {
  margin-bottom: 35px !important;
}

.u_ml-35 {
  margin-left: 35px !important;
}

.u_m-40 {
  margin: 40px !important;
}

.u_my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.u_mx-40 {
  margin-right: 40px !important;
  margin-left: 40px !important;
}

.u_mt-40 {
  margin-top: 40px !important;
}

.u_mr-40 {
  margin-right: 40px !important;
}

.u_mb-40 {
  margin-bottom: 40px !important;
}

.u_ml-40 {
  margin-left: 40px !important;
}

.u_m-45 {
  margin: 45px !important;
}

.u_my-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.u_mx-45 {
  margin-right: 45px !important;
  margin-left: 45px !important;
}

.u_mt-45 {
  margin-top: 45px !important;
}

.u_mr-45 {
  margin-right: 45px !important;
}

.u_mb-45 {
  margin-bottom: 45px !important;
}

.u_ml-45 {
  margin-left: 45px !important;
}

.u_m-50 {
  margin: 50px !important;
}

.u_my-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.u_mx-50 {
  margin-right: 50px !important;
  margin-left: 50px !important;
}

.u_mt-50 {
  margin-top: 50px !important;
}

.u_mr-50 {
  margin-right: 50px !important;
}

.u_mb-50 {
  margin-bottom: 50px !important;
}

.u_ml-50 {
  margin-left: 50px !important;
}

.u_m-55 {
  margin: 55px !important;
}

.u_my-55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}

.u_mx-55 {
  margin-right: 55px !important;
  margin-left: 55px !important;
}

.u_mt-55 {
  margin-top: 55px !important;
}

.u_mr-55 {
  margin-right: 55px !important;
}

.u_mb-55 {
  margin-bottom: 55px !important;
}

.u_ml-55 {
  margin-left: 55px !important;
}

.u_m-60 {
  margin: 60px !important;
}

.u_my-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.u_mx-60 {
  margin-right: 60px !important;
  margin-left: 60px !important;
}

.u_mt-60 {
  margin-top: 60px !important;
}

.u_mr-60 {
  margin-right: 60px !important;
}

.u_mb-60 {
  margin-bottom: 60px !important;
}

.u_ml-60 {
  margin-left: 60px !important;
}

.u_m-65 {
  margin: 65px !important;
}

.u_my-65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}

.u_mx-65 {
  margin-right: 65px !important;
  margin-left: 65px !important;
}

.u_mt-65 {
  margin-top: 65px !important;
}

.u_mr-65 {
  margin-right: 65px !important;
}

.u_mb-65 {
  margin-bottom: 65px !important;
}

.u_ml-65 {
  margin-left: 65px !important;
}

.u_m-70 {
  margin: 70px !important;
}

.u_my-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.u_mx-70 {
  margin-right: 70px !important;
  margin-left: 70px !important;
}

.u_mt-70 {
  margin-top: 70px !important;
}

.u_mr-70 {
  margin-right: 70px !important;
}

.u_mb-70 {
  margin-bottom: 70px !important;
}

.u_ml-70 {
  margin-left: 70px !important;
}

.u_m-75 {
  margin: 75px !important;
}

.u_my-75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}

.u_mx-75 {
  margin-right: 75px !important;
  margin-left: 75px !important;
}

.u_mt-75 {
  margin-top: 75px !important;
}

.u_mr-75 {
  margin-right: 75px !important;
}

.u_mb-75 {
  margin-bottom: 75px !important;
}

.u_ml-75 {
  margin-left: 75px !important;
}

.u_m-80 {
  margin: 80px !important;
}

.u_my-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.u_mx-80 {
  margin-right: 80px !important;
  margin-left: 80px !important;
}

.u_mt-80 {
  margin-top: 80px !important;
}

.u_mr-80 {
  margin-right: 80px !important;
}

.u_mb-80 {
  margin-bottom: 80px !important;
}

.u_ml-80 {
  margin-left: 80px !important;
}

.u_m-85 {
  margin: 85px !important;
}

.u_my-85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important;
}

.u_mx-85 {
  margin-right: 85px !important;
  margin-left: 85px !important;
}

.u_mt-85 {
  margin-top: 85px !important;
}

.u_mr-85 {
  margin-right: 85px !important;
}

.u_mb-85 {
  margin-bottom: 85px !important;
}

.u_ml-85 {
  margin-left: 85px !important;
}

.u_m-90 {
  margin: 90px !important;
}

.u_my-90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

.u_mx-90 {
  margin-right: 90px !important;
  margin-left: 90px !important;
}

.u_mt-90 {
  margin-top: 90px !important;
}

.u_mr-90 {
  margin-right: 90px !important;
}

.u_mb-90 {
  margin-bottom: 90px !important;
}

.u_ml-90 {
  margin-left: 90px !important;
}

.u_m-95 {
  margin: 95px !important;
}

.u_my-95 {
  margin-top: 95px !important;
  margin-bottom: 95px !important;
}

.u_mx-95 {
  margin-right: 95px !important;
  margin-left: 95px !important;
}

.u_mt-95 {
  margin-top: 95px !important;
}

.u_mr-95 {
  margin-right: 95px !important;
}

.u_mb-95 {
  margin-bottom: 95px !important;
}

.u_ml-95 {
  margin-left: 95px !important;
}

.u_m-100 {
  margin: 100px !important;
}

.u_my-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.u_mx-100 {
  margin-right: 100px !important;
  margin-left: 100px !important;
}

.u_mt-100 {
  margin-top: 100px !important;
}

.u_mr-100 {
  margin-right: 100px !important;
}

.u_mb-100 {
  margin-bottom: 100px !important;
}

.u_ml-100 {
  margin-left: 100px !important;
}

.u_p-0 {
  padding: 0px !important;
}

.u_py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.u_px-0 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.u_pt-0 {
  padding-top: 0px !important;
}

.u_pr-0 {
  padding-right: 0px !important;
}

.u_pb-0 {
  padding-bottom: 0px !important;
}

.u_pl-0 {
  padding-left: 0px !important;
}

.u_p-5 {
  padding: 5px !important;
}

.u_py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.u_px-5 {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.u_pt-5 {
  padding-top: 5px !important;
}

.u_pr-5 {
  padding-right: 5px !important;
}

.u_pb-5 {
  padding-bottom: 5px !important;
}

.u_pl-5 {
  padding-left: 5px !important;
}

.u_p-10 {
  padding: 10px !important;
}

.u_py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.u_px-10 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.u_pt-10 {
  padding-top: 10px !important;
}

.u_pr-10 {
  padding-right: 10px !important;
}

.u_pb-10 {
  padding-bottom: 10px !important;
}

.u_pl-10 {
  padding-left: 10px !important;
}

.u_p-15 {
  padding: 15px !important;
}

.u_py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.u_px-15 {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.u_pt-15 {
  padding-top: 15px !important;
}

.u_pr-15 {
  padding-right: 15px !important;
}

.u_pb-15 {
  padding-bottom: 15px !important;
}

.u_pl-15 {
  padding-left: 15px !important;
}

.u_p-20 {
  padding: 20px !important;
}

.u_py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.u_px-20 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.u_pt-20 {
  padding-top: 20px !important;
}

.u_pr-20 {
  padding-right: 20px !important;
}

.u_pb-20 {
  padding-bottom: 20px !important;
}

.u_pl-20 {
  padding-left: 20px !important;
}

.u_p-25 {
  padding: 25px !important;
}

.u_py-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.u_px-25 {
  padding-right: 25px !important;
  padding-left: 25px !important;
}

.u_pt-25 {
  padding-top: 25px !important;
}

.u_pr-25 {
  padding-right: 25px !important;
}

.u_pb-25 {
  padding-bottom: 25px !important;
}

.u_pl-25 {
  padding-left: 25px !important;
}

.u_p-30 {
  padding: 30px !important;
}

.u_py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.u_px-30 {
  padding-right: 30px !important;
  padding-left: 30px !important;
}

.u_pt-30 {
  padding-top: 30px !important;
}

.u_pr-30 {
  padding-right: 30px !important;
}

.u_pb-30 {
  padding-bottom: 30px !important;
}

.u_pl-30 {
  padding-left: 30px !important;
}

.u_p-35 {
  padding: 35px !important;
}

.u_py-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.u_px-35 {
  padding-right: 35px !important;
  padding-left: 35px !important;
}

.u_pt-35 {
  padding-top: 35px !important;
}

.u_pr-35 {
  padding-right: 35px !important;
}

.u_pb-35 {
  padding-bottom: 35px !important;
}

.u_pl-35 {
  padding-left: 35px !important;
}

.u_p-40 {
  padding: 40px !important;
}

.u_py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.u_px-40 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.u_pt-40 {
  padding-top: 40px !important;
}

.u_pr-40 {
  padding-right: 40px !important;
}

.u_pb-40 {
  padding-bottom: 40px !important;
}

.u_pl-40 {
  padding-left: 40px !important;
}

.u_p-45 {
  padding: 45px !important;
}

.u_py-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.u_px-45 {
  padding-right: 45px !important;
  padding-left: 45px !important;
}

.u_pt-45 {
  padding-top: 45px !important;
}

.u_pr-45 {
  padding-right: 45px !important;
}

.u_pb-45 {
  padding-bottom: 45px !important;
}

.u_pl-45 {
  padding-left: 45px !important;
}

.u_p-50 {
  padding: 50px !important;
}

.u_py-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.u_px-50 {
  padding-right: 50px !important;
  padding-left: 50px !important;
}

.u_pt-50 {
  padding-top: 50px !important;
}

.u_pr-50 {
  padding-right: 50px !important;
}

.u_pb-50 {
  padding-bottom: 50px !important;
}

.u_pl-50 {
  padding-left: 50px !important;
}

.u_p-55 {
  padding: 55px !important;
}

.u_py-55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

.u_px-55 {
  padding-right: 55px !important;
  padding-left: 55px !important;
}

.u_pt-55 {
  padding-top: 55px !important;
}

.u_pr-55 {
  padding-right: 55px !important;
}

.u_pb-55 {
  padding-bottom: 55px !important;
}

.u_pl-55 {
  padding-left: 55px !important;
}

.u_p-60 {
  padding: 60px !important;
}

.u_py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.u_px-60 {
  padding-right: 60px !important;
  padding-left: 60px !important;
}

.u_pt-60 {
  padding-top: 60px !important;
}

.u_pr-60 {
  padding-right: 60px !important;
}

.u_pb-60 {
  padding-bottom: 60px !important;
}

.u_pl-60 {
  padding-left: 60px !important;
}

.u_p-65 {
  padding: 65px !important;
}

.u_py-65 {
  padding-top: 65px !important;
  padding-bottom: 65px !important;
}

.u_px-65 {
  padding-right: 65px !important;
  padding-left: 65px !important;
}

.u_pt-65 {
  padding-top: 65px !important;
}

.u_pr-65 {
  padding-right: 65px !important;
}

.u_pb-65 {
  padding-bottom: 65px !important;
}

.u_pl-65 {
  padding-left: 65px !important;
}

.u_p-70 {
  padding: 70px !important;
}

.u_py-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.u_px-70 {
  padding-right: 70px !important;
  padding-left: 70px !important;
}

.u_pt-70 {
  padding-top: 70px !important;
}

.u_pr-70 {
  padding-right: 70px !important;
}

.u_pb-70 {
  padding-bottom: 70px !important;
}

.u_pl-70 {
  padding-left: 70px !important;
}

.u_p-75 {
  padding: 75px !important;
}

.u_py-75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

.u_px-75 {
  padding-right: 75px !important;
  padding-left: 75px !important;
}

.u_pt-75 {
  padding-top: 75px !important;
}

.u_pr-75 {
  padding-right: 75px !important;
}

.u_pb-75 {
  padding-bottom: 75px !important;
}

.u_pl-75 {
  padding-left: 75px !important;
}

.u_p-80 {
  padding: 80px !important;
}

.u_py-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.u_px-80 {
  padding-right: 80px !important;
  padding-left: 80px !important;
}

.u_pt-80 {
  padding-top: 80px !important;
}

.u_pr-80 {
  padding-right: 80px !important;
}

.u_pb-80 {
  padding-bottom: 80px !important;
}

.u_pl-80 {
  padding-left: 80px !important;
}

.u_p-85 {
  padding: 85px !important;
}

.u_py-85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

.u_px-85 {
  padding-right: 85px !important;
  padding-left: 85px !important;
}

.u_pt-85 {
  padding-top: 85px !important;
}

.u_pr-85 {
  padding-right: 85px !important;
}

.u_pb-85 {
  padding-bottom: 85px !important;
}

.u_pl-85 {
  padding-left: 85px !important;
}

.u_p-90 {
  padding: 90px !important;
}

.u_py-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.u_px-90 {
  padding-right: 90px !important;
  padding-left: 90px !important;
}

.u_pt-90 {
  padding-top: 90px !important;
}

.u_pr-90 {
  padding-right: 90px !important;
}

.u_pb-90 {
  padding-bottom: 90px !important;
}

.u_pl-90 {
  padding-left: 90px !important;
}

.u_p-95 {
  padding: 95px !important;
}

.u_py-95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}

.u_px-95 {
  padding-right: 95px !important;
  padding-left: 95px !important;
}

.u_pt-95 {
  padding-top: 95px !important;
}

.u_pr-95 {
  padding-right: 95px !important;
}

.u_pb-95 {
  padding-bottom: 95px !important;
}

.u_pl-95 {
  padding-left: 95px !important;
}

.u_p-100 {
  padding: 100px !important;
}

.u_py-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.u_px-100 {
  padding-right: 100px !important;
  padding-left: 100px !important;
}

.u_pt-100 {
  padding-top: 100px !important;
}

.u_pr-100 {
  padding-right: 100px !important;
}

.u_pb-100 {
  padding-bottom: 100px !important;
}

.u_pl-100 {
  padding-left: 100px !important;
}

.u_pos-relative {
  position: relative !important;
}

.u_pos-absolute {
  position: absolute !important;
}

.u_post-static {
  position: static !important;
}

.u_sr {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0);
  border: 0 !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  position: absolute !important;
}

.u_t-text {
  color: #333 !important;
}

.u_t-text-link {
  color: #f40000 !important;
}

.u_t-text-link-hover {
  color: #c00 !important;
}

.u_t-text-link-visited {
  color: #c00 !important;
}

.u_t-white {
  color: #fff !important;
}

.u_t-black {
  color: #231815 !important;
}

.u_t-bg {
  color: #f6f6f6 !important;
}

.u_t-thin-gray {
  color: #f6f6f6 !important;
}

.u_t-hilight-gray {
  color: #dbdbdb !important;
}

.u_t-light-gray {
  color: #a8a8a8 !important;
}

.u_t-gray {
  color: #4d4d4d !important;
}

.u_t-information {
  color: #008745 !important;
}

.u_t-event {
  color: #882784 !important;
}

.u_t-red {
  color: #c00 !important;
}

.u_t-left {
  text-align: left !important;
}

.u_t-center {
  text-align: center !important;
}

.u_t-right {
  text-align: right !important;
}

.u_t-smaller {
  font-size: smaller !important;
}

.u_t-larger {
  font-size: larger !important;
}

.u_va-baseline {
  vertical-align: baseline !important;
}

.u_va-top {
  vertical-align: top !important;
}

.u_va-middle {
  vertical-align: middle !important;
}

.u_va-bottom {
  vertical-align: bottom !important;
}

.u_va-text-bottom {
  vertical-align: text-bottom !important;
}

.u_va-text-top {
  vertical-align: text-top !important;
}

.u_vis-visible {
  visibility: visible !important;
}

.u_vis-hidden {
  visibility: hidden !important;
}

.u_wrap-wrap {
  white-space: normal !important;
}

.u_wrap-nowrap {
  white-space: nowrap !important;
}

.u_wrap-break-all {
  word-break: break-all !important;
}
