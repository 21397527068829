@charset "UTF-8";

.home {
  &-visual {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 720px;
    padding-bottom: 40px;
    background-color: c("white");
    @include mq-max("sm") {
      height: 360px;
      margin-bottom: 0;
    };
    p {
      position: relative;
      z-index: 300;
      img {
        width: 920px;
        @include mq-max("sm") {
          width: vw(600);
        };
      }
    }
  }
  &-slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    .sp-image {
      width: 100%;
    }
    .sp-button {
      border-color: #a8a8a8;
      background-color: #a8a8a8;
      &.sp-selected-button {
        border-color: #000;
        background-color: #000;
      }
    }
  }
  &-campaign {
    padding: 50px 0 0;
    text-align: center;
    background-color: c("white");
  }
  &-news {
    background-color: c("white");
    padding: 50px 0;
    @include mq-max("md") {
      padding: 50px 20px;
    };
    @include mq-max("sm") {
      padding: 50px 0;
    };
    .l-container {
      display: flex;
      align-items: center;
      @include mq-max("sm") {
        display: block;
      };
    }
    &-list {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        display: flex;
        padding: 12px 0;
        border-bottom: 1px solid c("hilight-gray");
        @include mq-max("sm") {
          flex-direction: column;
          padding: 20px 0;
        };
      }
    }
    &-content {
      flex: 1;
    }
    &-heading {
      flex: 0 0 225px;
      line-height: 1;
      @include mq-max("sm") {
        margin-bottom: 10px;
      };
      h2 {
        margin: 0;
        img {
          width: 160px;
          @include mq-max("sm") {
            width: vw(320);
          };
        }
      }
      p {
        font-size: rem(12);
        letter-spacing: 0;
        @include mq-max("sm") {
          font-size: rem(14);
          margin-bottom: 0;
        };
      }
    }
    &-date {
      flex: 0 0 230px;
      font-size: rem(14);
      @include mq-max("sm") {
        flex: 1;
        margin-bottom: 10px;
      };
      .m-category {
        margin-left: 1em;
      }
    }
    &-title {
      flex: 1 1 1;
      font-size: rem(14);
      color: c("text");
      text-decoration: underline;
      &:visited {
        color: c("text");
      }
      &:hover {
        text-decoration: none;
      }
    }
    &-footer {
      text-align: right;
      @include mq-max("sm") {
        padding-top: 10px;
      };
      a {
        font-size: rem(14);
        color: c("text");
      }
    }
  }
  &-make {
    border-top: 1px solid c("light-gray");
    padding-top: 35px;
    h2 {
      margin: 0 0 50px;
      font-size: rem(32);
      font-weight: normal;
      text-align: center;
      line-height: 1;
      @include mq-max("sm") {
        margin-bottom: 30px;
        font-size: rem(24);
      };
    }
    &-container {
      display: flex;
      a {
        display: block;
        flex: 1 0 50%;
        transition: opacity .15s;
        &:hover {
          text-decoration: none;
          color: c("white");
          opacity: .75;
          img {
            opacity: 1;
          }
        }
        img {
          width: 100%;
        }
        h3 {
          margin: 0;
          background-color: c("gray");
          color: c("white");
          text-align: center;
          font-size: rem(22);
          line-height: 52px;
          font-weight: normal;
          @include mq-max("sm") {
            font-size: rem(18);
            line-height: 36px;
          };
        }
        &:first-of-type {
          h3 {
            background-color: c("black");
          }
        }
      }
    }
  }
  &-simple {
    padding: 85px 20px 35px;
    @include mq-max("sm") {
      padding: 40px 0;
    };
    &-container {
      display: flex;
      @include mq-max("sm") {
        flex-direction: column;
      };
    }
    &-img {
      width: per(620, 1000);
      margin-right: 10px;
      @include mq-max("sm") {
        width: 100%;
        margin: 0 0 15px;
      };
      img {
        width: 100%;
      }
    }
    &-content {
      display: flex;
      flex-direction: column;
      width: 340px;
      margin-left: auto;
      @include mq-max("sm") {
        width: auto;
        margin: 0;
        text-align: center;
      };
      h2 {
        margin: 0;
        letter-spacing: .05em;
      }
      p {
        font-size: rem(14);
        line-height: 2;
        letter-spacing: 0;
        @include mq-max("sm") {
          font-size: rem(12);
          margin-bottom: 30px;
        };
      }
    }
    &-btn {
      display: block;
      padding: 20px 0;
      margin-top: auto;
      background-color: c("black");
      text-align: center;
      color: c("white");
      transition: all .15s;
      @include mq-max("sm") {
        width: 100%;
      };
      &:visited {
        color: c("white");
      }
      &:hover {
        opacity: .75;
        color: c("white");
        text-decoration: none;
        img {
          opacity: 1;
        }
      }
      div {
        width: 270px;
        margin: 0 auto 12px;
        padding: 8px 0 14px;
        border: 1px solid c("white");
        @include mq-max("sm") {
          width: vw(500);
          padding: 10px 0;
        };
        img {
          width: 228px;
          @include mq-max("sm") {
            width: 80%;
          };
        }
      }
      p {
        font-size: rem(14);
        margin: 0;
        @include mq-max("sm") {
          line-height: 1;
        };

      }
    }
    &-images {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 35px;
      div {
        flex: 0 0 23%;
        margin: 20px auto 0 0;
        @include mq-max("sm") {
          flex: 0 0 48%;
        };
        &:nth-of-type(4) , &:nth-of-type(8) {
          margin-right: 0;
        }
        &:nth-of-type(even) {
          @include mq-max("sm") {
            margin-right: 0;
          };
        }
        img {
          width: 100%;
        }
      }
    }
  }
  &-exhibition {
    padding: 0 20px 90px;
    @include mq-max("sm") {
      padding: 0 0 50px;
    };
    &-container {
      display: flex;
      padding: 30px 30px 30px 45px;
      background-color: c("white");
      border: 1px solid #000;
      @include mq-max("sm") {
        padding: 20px;
        flex-direction: column;
      };
    }
    &-content {
      margin-right: 10px;
      @include mq-max("sm") {
        text-align: center;
        margin-bottom: 20px;
      };
      h2 {
        margin: 0;
        font-weight: normal;
        font-size: rem(31);
      }
      p {
        letter-spacing: .26em;
        @include mq-max("sm") {
          font-size: rem(14);
        };
        img {
          display: inline-block;
          width: 175px;
          margin-top: 10px;
          @include mq-max("sm") {
            width: vw(480);
          };
        }
      }
    }
    &-map {
      flex: 0 0 auto;
      width: 415px;
      margin-left: auto;
      @include mq-max("sm") {
        width: 100%;
        height: vw(360);
      };
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
  &-access {
    padding-bottom: 60px;
    @include mq-max("sm") {
      padding-bottom: 40px;
    };
    h2 {
      margin: 0;
      font-size: rem(21);
      text-align: center;
      letter-spacing: .1em;
      line-height: 1;
    }
    &-container {
      display: flex;
      padding-top: 60px;
      @include mq-max("sm") {
        flex-direction: column;
        padding-top: 30px;
      };
      div {
        flex: 0 0 49%;
        img {
          width: 100%;
        }
      }
    }
    &-map {
      margin-left: auto;
      @include mq-max("sm") {
        margin: 10px 0 0;
        height: vw(480);
      };
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
}

// ----------------------------------------
// ホームZEH
// ----------------------------------------
.home-zeh {
  padding: 0 20px 35px;
  margin-top: -18px;

  @include mq-max("sm") {
    padding: 0 0 40px 0;
    margin-top: -23px;
  }
}
.home-zeh-banner p {
  display: block;

  font-size: 2.5rem;
  letter-spacing: 6px;
  color: #fff;
  text-align: center;
  background-color: #96C165;
  border-radius: 10px;
  
  transition: opacity 0.15s;
  padding: 15px 0;
  margin: 0;

  @include mq-max("sm") {
    // node-sassでは「min(◯◯vw, ◯◯px)」がエラーになるのでunquoteで囲む
    font-size: unquote("min(4.9vw, 32px)");
    letter-spacing: 3px;
    padding: 20px 0;
  }
}
.home-zeh-banner:hover {
  color: #fff;
  text-decoration: none;
  opacity: 0.75;
}