@charset "UTF-8";

.l-side {
  flex: 0 0 auto;
  width: 270px;
  margin-right: 40px;
  @include mq-max("lg") {
    padding-left: 10px;
    width: 220px;
  };
  @include mq-max("sm") {
    width: 100%;
    margin-right: 0;
    padding-left: 0;
    margin-top: 100px;
  };
  ul {
    list-style: none;
  }
  &_list {
    margin: 0;
    padding: 0;
    @include mq-max("sm") {
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid c("gray3");
      background-color: #f2f2f2;
    };
    &>li {
      @include mq-max("sm") {
        flex: 0 0 50%;
        border-right: 1px solid c("gray3");
        border-bottom: 1px solid c("gray3");
      };
      &:nth-of-type(even) {
        @include mq-max("sm") {
          border-right: 0;
        };
      }
    }
  }
  &_item {
    position: relative;
    display: block;
    margin-bottom: 30px;
    padding-left: 1.5em;
    font-size: rem(18);
    font-weight: bold;
    color: c("black");
    line-height: 1;
    @include mq-max("sm") {
      display: flex;
      height: 100%;
      align-items: center;
      background-color: #f2f2f2;
      margin-bottom: 0;
      font-size: rem(14);
      font-weight: bold;
      padding: 20px;
      line-height: 1;
    };
    &:visited {
      color: c("black");
    }
    &::before {
      position: absolute;
      display: inline-block;
      content: "";
      left: 0;
      top: .5em;
      width: 20px;
      height: 1px;
      margin-right: 10px;
      background-color: c("border");
      vertical-align: middle;
      @include mq-max("sm") {
        display: none;
      };
    }
    &._has-child {
      margin-bottom: 20px;
      @include mq-max("sm") {
        margin-bottom: 0;
      };
    }
    &._active {
      color: c("red");
    }
  }
  &_child {
    padding: 0;
    margin: 0 0 30px 50px;
    @include mq-max("sm") {
      display: none;
    };
    a {
      display: block;
      margin-bottom: 16px;
      color: c("black");
      font-weight: bold;
      &:visited {
        color: c("black");
      }
    }
  }
  &._fixed {
    .l-side_list {
      position: absolute;
      @include mq-max("sm") {
        position: relative;
        top: 0 !important;
      };
    }
  }
}
