@charset "UTF-8";

.c-pagination {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  @include mq-max("sm") {
    align-items: center;
    margin-top: 30px;
  };
  .page-numbers {
    display: inline-block;
    width: 50px;
    line-height: 50px;
    margin: 0 6px;
    text-align: center;
    background-color: c("white");
    border: 1px solid c("light-gray");
    transition: all .15s;
    color: c("black");
    @include mq-max("sm") {
      width: vw(60);
      line-height: vw(60);
      margin: 0 3px;
    };
    &.current {
      background-color: c("black");
      border: 1px solid c("black");
      color: c("white");
      font-weight: bold;
    }
    &:hover {
      text-decoration: none;
      background-color: c("red");
      border: 1px solid c("red");
      color: c("white");
    }
    &.next , &.prev {
      width: auto;
      border: 0;
      padding: 0 1em;
      margin: 0 15px;
      font-size: rem(16);
      @include mq-max("sm") {
        font-size: rem(16);
        margin: 0 5px;
        padding: 0 .5em;
      };
      &:hover {
        .m-icon {
          span {
            background-color: c("white");
          }
        }
      }
    }
  }
}
