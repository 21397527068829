@charset "UTF-8";

@for $i from 0 through 20 {
  $num: $i * 5;
  .u_p-#{$num} {
    padding: #{$num}px !important;
  }
  .u_py-#{$num} {
    padding-top: #{$num}px !important;
    padding-bottom: #{$num}px !important;
  }
  .u_px-#{$num} {
    padding-right: #{$num}px !important;
    padding-left: #{$num}px !important;
  }
  .u_pt-#{$num} {
    padding-top: #{$num}px !important;
  }
  .u_pr-#{$num} {
    padding-right: #{$num}px !important;
  }
  .u_pb-#{$num} {
    padding-bottom: #{$num}px !important;
  }
  .u_pl-#{$num} {
    padding-left: #{$num}px !important;
  }
}
