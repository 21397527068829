@charset "UTF-8";

.p-footer {
  margin-top: 80px;
  border-top: 1px solid #a8a8a8;
  background-color: c("white");
  text-align: center;
  @include mq-max("sm") {
    margin-top: 40px;
  };
  &_content {
    padding: 40px 0 15px;
    @include mq-max("sm") {
      padding: 30px 0 15px;
    };
    h3 {
      margin: 0;
      font-size: rem(21);
      line-height: 1;
      img {
        width: 159px;
      }
    }
    p {
      margin: 10px 0 20px;
      color: #666;
      font-size: rem(14);
      @include mq-max("sm") {
        margin: 10px 0;
      };
    }
  }
  &_copy {
    font-size: rem(11);
    color: #666;
  }
  &_nav {
    display: flex;
    @include mq-max("sm") {
      flex-direction: column;
    };
    a {
      flex: 1;
      display: block;
      color: c("white");
      background-color: #000;
      line-height: 60px;
      transition: opacity .15s;
      &:hover {
        opacity: .75;
        text-decoration: none;
      }
      svg {
        display: inline-block;
        margin-right: 5px;
        max-height: 28px;
      }
      &._tel {
        border-right: 1px solid c("white");
        @include mq-max("sm") {
          border-right: 0;
          border-bottom: 1px solid c("white");
        };
        svg {
          width: 18px;
        }
      }
      &._document {
        border-right: 1px solid c("white");
        @include mq-max("sm") {
          border-right: 0;
          border-bottom: 1px solid c("white");
        };
        svg {
          width: 24px;
        }
      }
      &._contact {
        border-right: 1px solid c("white");
        @include mq-max("sm") {
          border-right: 0;
          border-bottom: 1px solid c("white");
        };
        svg {
          width: 28px;
        }
      }
      &._top {
        position: relative;
        flex: 0 0 auto;
        width: 100px;
        @include mq-max("sm") {
          width: auto;
          height: 60px;
        };
        span {
          position: absolute;
          display: block;
          width: 24px;
          height: 1px;
          top: 50%;
          left: 50%;
          background-color: c("white");
          &:first-of-type {
            transform: rotate(-60deg);
            margin-left: -18px;
          }
          &:last-of-type {
            transform: rotate(60deg);
            margin-left: -6px;
          }
        }

      }
    }
  }
}
