@charset "UTF-8";

.zeh {
}
.zeh .c-visual {
  background: url(../images/zeh/visual.jpg) center no-repeat;
  background-size: cover;
}
.zeh .c-visual div {
  line-height: 1.4;
  background-color: transparent;
  color: transparent;
  background: url(../images/zeh/zeh-title.png) center no-repeat;
  background-size: contain;
  width: 90%;
  max-width: 600px;
}
.zeh .c-visual_title {
  margin: 0;
}
.zeh-description {
  margin: 60px 0;
  font-size: rem(32);
  text-align: center;
  letter-spacing: 0.03em;

  @include mq-max("sm") {
    margin: 30px 4vw;
    font-size: 24px;
    letter-spacing: 0.08em;
  }  
}
.zeh-container {
  max-width: 780px;
  width: 100%;
  padding: 0 32px;
  margin: 0 auto;

  @include mq-max("sm") {
    padding: 0 16px;
  }  
}
.zeh-section + .zeh-section {
  margin-top: 90px;

  @include mq-max("sm") {
    margin-top: 60px;
  }  
}
.zeh-section h2, .zeh-section h3 {
  margin: 0;
  line-height: 1;
  font-size: rem(20);
  font-weight: 400;
  letter-spacing: 0;
  text-align: center;

  @include mq-max("sm") {
    font-size: 18px;
  }  
}
.zeh-section-image {
  margin: 25px 0 0 0;
}
.zeh-section p {
  font-size: rem(14);
  letter-spacing: 0;
  margin-top: 20px;
  margin-bottom: 0;
}
.zeh-box {
  height: 147px;
  background-color: #E8E8E8;
  border: 1px solid rgba(112, 112, 112, 0.73);
  margin-top: 90px;

  @include mq-max("sm") {
    height: 70px;
    margin-top: 60px;
  }  
}

.zeh-section .zeh-section-copy {
  font-weight: 700;
  font-size: 2.15rem;
  line-height: 1.45;
  color: #00AC4D;
  text-align: center;

  @include mq-max("sm") {
    font-size: 2.0rem;
  }  
}
.zeh-section-logo {
  max-width: 300px;
  margin: 24px auto -32px auto;

  @include mq-max("sm") {
  }  
}

