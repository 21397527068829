@charset "UTF-8";

.p-header {
  position: fixed;
  width: 100%;
  background: c("white");
  z-index: z("header");
  top: 0;
  left: 0;
  &_container {
    position: relative;
    height: 80px;
    display: flex;
    width: 1000px;
    margin: 0 auto;
    @include mq-max("md") {
      width: auto;
      height: 60px;
    };
  }
  &_logo {
    position: relative;
    margin: 20px auto 0 0;
    @include mq-max("md") {
      display: flex;
      align-items: center;
      flex: 1;
      height: 100%;
      margin: 0;
      position: relative;
      padding-left: vw(20);
      background-color: c("white");
      z-index: z("header");
    };
    a {
      display: block;
      font-size: rem(12);
      color: c("black");
      line-height: 1;
      font-weight: bold;
      letter-spacing: .1em;
      transition: all .15s;
      &:hover {
        text-decoration: none;
        opacity: .5;
        img {
          opacity: 1;
        }
      }
      span {
        @include mq-max("md") {
          vertical-align: middle;
        };
      }
    }
    img {
      display: block;
      width: 176px;
      margin-bottom: 6px;
      @include mq-max("md") {
        display: inline-block;
        width: 120px;
        margin: 0 10px 0 0;
      };
    }
  }
}
