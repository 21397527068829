@charset "UTF-8";

.simple-note {
  .c-visual {
    background: url("../images/simple-note/visual.jpg") center no-repeat;
    background-size: cover;
  }
  &-description {
    margin: 50px 0;
    font-size: rem(21);
    text-align: center;
    letter-spacing: .34em;
    @include mq-max("sm") {
      margin: 30px vw(30);
      font-size: rem(16);
      letter-spacing: .08em;
      br {
        display: none;
      }
    };
  }
  &-main {
    height: 1125px;
    background: url("../images/simple-note/img-1.jpg") center no-repeat;
    background-size: cover;
    color: c("white");
    @include mq-max("sm") {
      height: auto;
      padding-bottom: 100px;
    };
  }
  &-container {
    display: flex;
    align-items: center;
    padding-top: 100px;
    margin: 0 0 460px;
    @include mq-max("sm") {
      flex-direction: column;
      align-items: flex-start;
      padding-top: 40px;
      margin-bottom: 40px;
    };
    h2 {
      margin-right: auto;
      font-size: rem(28);
      letter-spacing: .34em;
      @include mq-max("sm") {
        font-size: rem(20);
      };
    }
    p {
      font-size: rem(17);
      letter-spacing: .34em;
      @include mq-max("sm") {
        font-size: rem(14);
        letter-spacing: .08em;
      };
    }
  }
  &-text {
    font-size: rem(28);
    letter-spacing: .34em;
    @include mq-max("sm") {
      font-size: rem(20);
      letter-spacing: .08em;
    };
  }
  &-gallery {
    padding-top: 70px;
    @include mq-max("sm") {
      padding-top: 40px;
    };
    h3 {
      margin: 0 0 45px;
      text-align: center;
      font-size: rem(28);
      font-weight: normal;
      letter-spacing: .34em;
      @include mq-max("sm") {
        font-size: rem(20);
        margin-bottom: 30px;
        letter-spacing: .1em;
      };
    }
    &-container {
      display: flex;
      flex-wrap: wrap;
      a , div {
        display: block;
        flex: 0 0 per(312, 1000);
        margin-right: auto;
        margin-bottom: 30px;
        @include mq-max("sm") {
          flex: 0 0 49%;
          margin-bottom: 15px;
        };
        &:nth-of-type(3n) {
          margin-right: 0;
          @include mq-max("sm") {
            margin-right: auto;
          };
        }
        &:nth-of-type(even) {
          @include mq-max("sm") {
            margin-right: 0;
          };
        }
      }
    }
  }
  &-exhibition {
    display: flex;
    align-items: center;
    padding: 30px 30px 30px 40px;
    margin-bottom: 40px;
    border: 1px solid c("text");
    background-color: c("white");
    @include mq-max("sm") {
      flex-direction: column;
      padding: 20px;
    };
    &-container {
      h3 {
        margin: 0;
        font-size: rem(21);
        letter-spacing: .34em;
        @include mq-max("sm") {
          font-size: rem(18);
          letter-spacing: .08em;
        };
        img {
          display: inline-block;
          margin-left: 10px;
          width: 160px;
        }
      }
      p {
        margin: 1em 0 0;
        font-size: rem(15);
        letter-spacing: .34em;
        @include mq-max("sm") {
          font-size: rem(14);
          letter-spacing: .08em;
        };
      }
    }
    &-img {
      flex: 0 0 220px;
      margin-left: 20px;
      @include mq-max("sm") {
        flex: 1 0 auto;
        margin: 20px 0 0;
      };
    }
  }
}
