@charset "UTF-8";

.make {
  .c-visual {
    background: url("../images/make/visual.jpg") center no-repeat;
    background-size: cover;
  }
  &-section {
    margin-bottom: 50px;
    @include mq-max("sm") {
      margin-bottom: 30px;
    };
    & + .make-section {
      margin-top: 100px;
      @include mq-max("sm") {
        margin-top: 50px;
      };
    }
    .l-flex_img {
      flex: 1 0 auto;
      img + a {
        display: block;
        margin-top: 40px;
      }
    }
    .l-flex_img + .l-flex_content {
      margin-left: 40px;
      @include mq-max("sm") {
        margin-left: 0;
        margin-top: 20px;
      };
    }
    .l-flex_content + .l-flex_img {
      margin-left: 40px;
      @include mq-max("sm") {
        margin-top: 20px;
        margin-left: 0;
      };
    }
    p {
      margin: 0;
      letter-spacing: .22em;
      line-height: 2;
      @include mq-max("sm") {
        letter-spacing: .08em;
        font-size: rem(14);
      };
    }
  }
}
