@charset "UTF-8";

.p-nav {
  display: flex;
  flex-direction: column;
  @include mq-max("md") {
    position: fixed;
    flex-direction: column-reverse;
    right: -100%;
    width: 100%;
    height: 100%;
    padding: 60px 0 40px;
    background-color: c("black");
    z-index: z("nav");
    transition: all .15s;
    overflow-y: auto;
  };
  &_container {
    flex: 1 1 auto;
    @include mq-max("md") {
      flex: 0;
    };
  }
  &_list {
    display: flex;
    list-style: none;
    height: 100%;
    margin: 0;
    padding: 0;
    @include mq-max("md") {
      margin-bottom: 30px;
      height: auto;
      flex-direction: column;
    };
  }
  &_link {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    padding: 0 .5em;
    margin: 0 5px;
    font-size: rem(14);
    color: c("text");
    line-height: 1;
    text-align: center;
    left: .08em;
    @include mq-max("md") {
      color: c("white");
      margin-left: 0;
      padding: 1em vw(30);
      text-align: left;
      font-size: rem(16);
      border-bottom: 1px solid c("hilight-gray");
    };
    &:visited {
      color: c("text");
      @include mq-max("md") {
        color: c("white");
      };
    }
    &:hover , &._active {
      color: c("text");
      text-decoration: none;
      .p-nav_text {
        &::after {
          opacity: 1;
        }
      }
    }
  }
  &_text {
    position: relative;
    display: block;
    height: 100%;
    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: c("black");
      opacity: 0;
      transition: opacity .15s;
      @include mq-max("md") {
        width: 12px;
        height: 2px;
        background-color: c("white");
        opacity: 1;
        left: auto;
        right: 0;
        transform: rotate(-45deg);
      };
    }
    &::before {
      @include mq-max("md") {
        position: absolute;
        content: "";
        width: 12px;
        height: 2px;
        bottom: 50%;
        right: 0;
        background-color: c("white");
        transform: rotate(45deg);
      };
    }
  }
  &_sub {
    margin-left: auto;
    padding: 10px 0;
    text-align: right;
    @include mq-max("md") {
      flex: 1;
      margin-left: 0;
      padding: 0 vw(30);
    };
  }
  &_contact {
    display: inline-block;
    padding: .1em 1.5em;
    background-color: c("hilight-gray");
    border-radius: 1em;
    color: c("text");
    font-size: rem(12);
    font-weight: bold;
    transition: all .15s;
    @include mq-max("md") {
      width: 100%;
      text-align: center;
      line-height: 36px;
      font-size: rem(18);
    };
    &:visited {
      color: c("text");
    }
    &:hover {
      text-decoration: none;
      background-color: c("light-gray");
      color: c("white");
    }
    svg {
      width: 16px;
      height: 11.52px;
      margin-right: .5em;
      @include mq-max("md") {
        width: 18px;
        height: auto;
      };
    }
  }
  &_btn {
    display: none;
    @include mq-max("md") {
      position: fixed;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 0;
      top: 0;
      width: 60px;
      height: 60px;
      background-color: c("black");
      z-index: 600;
      transition: all .15s;
    }
  }
  &_icon {
    position: relative;
    display: block;
    width: 20px;
    height: 16px;
    padding: 0;
    span {
      position: absolute;
      display: block;
      width: 20px;
      height: 2px;
      background-color: c("white");
      transform: translateY(-50%);
      transition: all .15s;
      &:first-of-type {
        top: 0;
      }
      &:nth-of-type(2) {
        top: 50%;
      }
      &:last-of-type {
        top: 100%;
      }
    }
  }
}
