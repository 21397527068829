@charset "UTF-8";

.p-header._open {
  @include mq-max("md") {
      .p-nav {
        right: 0;
      }
      .p-nav_btn {
        background-color: c("black");
      }
      .p-nav_icon {
        span {
          transform: translateY(-50%);
          &:first-of-type {
            top: 50%;
            transform: rotate(-45deg);
          }
          &:nth-of-type(2) {
            opacity: 0;
          }
          &:last-of-type {
            top: 50%;
            transform: rotate(45deg);
          }
        }
      }
    };
}
