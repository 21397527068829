@charset "UTF-8";

.c-visual {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 270px;
  margin-bottom: 60px;
  @include mq-max("sm") {
    height: 180px;
    margin-bottom: 40px;
  };
  div {
    padding: 15px 20px;
    background-color: #000;
    color: c("white");
    text-align: center;
    @include mq-max("sm") {
      padding: 10px 15px;
    };
  }
  &_title {
    font-size: rem(32);
    font-weight: normal;
    line-height: 1;
    margin: 0 0 10px;
    @include mq-max("sm") {
      font-size: rem(24);
    };
  }
  &_text {
    margin: 0;
    font-size: rem(14);
    line-height: 1;
  }
}
