@charset "UTF-8";

.contact {
  .c-visual {
    background: url("../images/contact/visual.jpg") center no-repeat;
    background-size: cover;
  }
  &-description {
    text-align: center;
    margin-bottom: 60px;
    @include mq-max("sm") {
      margin: 0 vw(30);
    };
    img {
      width: 50px;
    }
    p {
      font-size: rem(15);
      line-height: 2;
      letter-spacing: .34em;
    }
  }
}
