@charset "UTF-8";

.l-body {
  flex: 1;
  @include mq-max("lg") {
    margin: 0 10px;
  };
  @include mq-max("sm") {
    margin: 0;
  };
  p {
    @include mq-max("sm") {
      margin: .5em 0;
      font-size: rem(13);
      line-height: 2;
    };
  }
}
