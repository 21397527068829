@charset "UTF-8";

@for $i from 1 through 10 {
  $num: $i * .05;
  .u_ls-#{$num * 100} {
    letter-spacing: #{$num}em !important;
  }
}
@for $i from 1 through 10 {
  $num: $i * .05;
  .u_ls-minus#{$num * 100} {
    letter-spacing: -#{$num}em !important;
  }
}
.u_ls-normal {
  letter-spacing: normal !important;
}
