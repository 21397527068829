@charset "UTF-8";

.flow {
  .c-visual {
    background: url("../images/flow/visual.jpg") center no-repeat;
    background-size: cover;
  }
  &-description {
    margin: 60px 0;
    font-size: rem(24);
    text-align: center;
    letter-spacing: .42em;
    @include mq-max("sm") {
      margin: 30px vw(30);
      font-size: rem(16);
      letter-spacing: .08em;
    };
  }
  &-container {
    display: flex;
    max-width: 936px;
    width: 100%;
    margin: 0 auto;
    flex-wrap: wrap;
  }
  &-section {
    flex: 1 0 1;
    max-width: 432px;
    width: 100%;
    height: 200px;
    margin: 16px;
    padding: 20px;
    background-color: c("hilight-gray");
    font-size: rem(15);
    h2 {
      margin: 0;
      line-height: 1;
      font-size: rem(26);
      font-weight: normal;
      letter-spacing: 0;
      @include mq-max("sm") {
        font-size: rem(20);
      };
      img {
        display: inline-block;
        height: 38px;
        margin-right: 14px;
        @include mq-max("sm") {
          height: vw(48);
          margin-right: 10px;
        };
      }
    }
    p {
      margin-bottom: 0;
      letter-spacing: 0;
    }
  }
  .p-contact {
    max-width: 900px;
  }
}
