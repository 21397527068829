@charset "UTF-8";

.l-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  @include mq-max("sm") {
    width: auto;
    margin: 0;
  };
  &._flex {
    display: flex;
    @include mq-max("sm") {
      flex-direction: column-reverse;
    };
  }
  &._has-subnav {
    @include mq-max("sm") {
      display: flex;
      flex-direction: column-reverse;
    };
  }
}
